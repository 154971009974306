import React, { useState, useEffect } from "react";
import Spinner from "../../utils/spinner/Spinner";
import { toast } from "react-toastify";
import { useSymbolContext } from "../../../contexts/Symbol-Context.js";
import { API_ENDPOINT_OPEN_ORDER } from "../../../data/Endpoints-API.js";
import APIMiddleware from "../../../data/api/Api-Middleware.js";
import { useAccountManagerContext } from "../../../contexts/Account-Manager-Context.js";
import { useAuthContext } from "../../../contexts/Auth-Context.js";
import { useMetricsContext } from "../../../contexts/Metrics-Context.js";
import { Order } from "../../../interfaces/Order.js";
import { formatPositionToPipSize, formatPrice } from "../../../utils/format.js";
import { useOrderContext } from "../../../contexts/Order-Context.js";
import SLTP from "../market_order/SLTP.jsx";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

//Declaring props
interface ChildProps {
  selectedOrderTab: any;
}

const PendingOrder: React.FC<ChildProps> = ({ selectedOrderTab }) => {
  //CONTEXT
  const {
    loadingSymbolContext,
    
    bidPrice,
    askPrice,
    symbolInfo,
    marketHours,
    symbolData,
    updateSymbolData,
    allSymbolOptions,
    selectedSymbolOption,
    handleSymbolOptionChange,
    leverage,
    mkRipple,
  } = useSymbolContext();
  const { exchangeRate, quoteExchangeRate } = useOrderContext();
  const { openOrder, setActiveTab } = useAccountManagerContext();
  const { user, sendDataToServer,selectedAuthSymbol } = useAuthContext();
  const { metrics } = useMetricsContext();
  const [buyActive, setBuyActive] = useState(true);
  const [sellActive, setSellActive] = useState(false);
  const [direction, setDirection] = useState("Buy");
  const [orderQuantity, setOrderQuantity] = useState(0.0);
  const [stopLoss, setStopLoss] = useState(0.0);
  const [takeProfit, setTakeProfit] = useState(0.0);
  const [orderEntryPrice, setOrderEntryPrice] = useState(0.0);
  const [expiresAt, setExpiresAt] = useState("");

  const [pointerEvents, setPointerEvents] = useState("all");

  const [preDefineQuantities, setPreDefineQuantities] = useState("false");
  const [orderComment, setOrderComment] = useState("");
  const [assetState, setAssetState] = useState({
    type: "base_asset",
    name: symbolInfo.base_asset,
  });
  const [amount, setAmount] = useState(0.0);
  const [expiresAtStatus, setExpiresAtStatus] = useState(false);
  const [isOrderOpening, setIsOrderOpening] = useState(false);


  const handleProfitChange = (data) => {
    setTakeProfit(data)
  };
  const handleLossChange = (data) => {
    setStopLoss(data)
  };


  useEffect(() => {
    // setEntryPrice(direction == "Buy" ? bidPrice : askPrice);
  }, [
    bidPrice,
    askPrice,
    orderEntryPrice,
  ]);

  useEffect(() => {
    if (selectedOrderTab === "pending-order" && bidPrice > 0 && askPrice) {
      setOrderEntryPrice(
        direction == "Buy" ? parseFloat(bidPrice) : parseFloat(askPrice)
      );
    }
  }, [selectedOrderTab, direction, selectedAuthSymbol]);

  useEffect(() => {
    setTakeProfit(0.0);
    setStopLoss(0.0);
  }, [selectedAuthSymbol]);


  const handleDateTimeChange = (event) => {
    setExpiresAt(event);
  };

  useEffect(() => {
    //If amount entry price and quantity is not null or nan then it will pass to the condition
    if (!isNaN(amount) && !isNaN(orderEntryPrice) && !isNaN(orderQuantity)) {
      //If the selected type is base asset (BTC)
      //then it will set amount according to given quantity
      if (assetState.type === "base_asset") {
        setAmount(orderEntryPrice * orderQuantity);
      }
      //If the selected type is quote asset (USDT)
      //then it will set quantity according to given amount
      else if (assetState.type === "quote_asset") {
        setOrderQuantity(amount / orderEntryPrice);
      }
    }
  }, [amount, orderQuantity, orderEntryPrice]);

  const handleAssetChange = (e) => {
    const { value } = e.target;

    setAmount(0.0);
    setOrderQuantity(0.0);

    // Create a new object with updated values based on selection
    const updatedAssetState = {
      type: value === symbolInfo.base_asset ? "base_asset" : "quote_asset",
      name: value,
    };

    // Update the state using the updated object
    setAssetState(updatedAssetState);
  };

  const expiryDate = (e) => {
    if (e.target.checked == true) {
      e.target.parentNode.children[0].setAttribute("aria-checked", true);
      // e.target.parentNode.children[2].removeAttribute("disabled");
      setExpiresAtStatus(e.target.checked);
    } else {
      e.target.parentNode.children[0].setAttribute("aria-checked", false);
      // e.target.parentNode.children[2].setAttribute("disabled", true);
      setExpiresAt("");
      setExpiresAtStatus(e.target.checked);
    }
  };


  const countLetters = (e) => {
    const counter = e.target.value.split("").length;
    const countBox = document.getElementById("count-comment-letters");
    if (counter <= 100 && countBox != null) {
      countBox.innerText = counter;
    } else {
      e.target.value = e.target.value.slice(0, 100);
    }
  };

  const customCheckbox = (e) => {
    if (e.target.checked == true) {
      e.target.parentNode.children[5].setAttribute("role", "checked");
    } else {
      e.target.parentNode.children[5].removeAttribute("role", "checked");
    }
  };

  const handleBuySellOrderClick = (buy: boolean) => {
    setBuyActive(buy);
    setSellActive(!buy);
    setDirection(buy ? "Buy" : "Sell");
    // setEntryPrice(buy ? bidPrice : askPrice);
  };

  const placeOrder = () => {
    if (user && user.userId != undefined && user.userId > 0) {
      setIsOrderOpening(true);
      setActiveTab("open-orders-acc");
      placePendingOrder();
    }
  };

  const placePendingOrder = () => {
    if (orderEntryPrice > 0) {      
      if ((buyActive || sellActive) && orderQuantity > 0 && orderEntryPrice > 0) {
        // Calculate required margin based on trading logic
        const requiredMargin = calculateOrderRequiredMargin();
  
        // Check if the user has enough balance
        if (metrics.freeMargin >= requiredMargin) {
          // Continue with opening the position
          openPendingOrder_api(requiredMargin);
        } else {
          // Show an alert for insufficient balance
          toast.error("Insufficient balance to open the order.", {
            position: "top-right",
          });
          document.getElementById("closeSound").play();
          setIsOrderOpening(false);
        }
      } else {
        toast.error("Order Quantity should be greater than 0.", {
          position: "top-right",
        });
        setIsOrderOpening(false);
      }
    }else{
      toast.error("Entry Price should be greater than 0.", {
        position: "top-right",
      });
      setIsOrderOpening(false);
    }
  };

  const calculateOrderRequiredMargin = () => {
    const lvg =
      leverage[0]?.available_leverage > 0 ? leverage[0]?.available_leverage : 1;
    const orderRequiredMargin = orderQuantity * (orderEntryPrice / lvg);
    // const orderRequiredMargin = quantity * orderEntryPrice;
    const convertedRequiredMargin = orderRequiredMargin * exchangeRate;
    return isNaN(convertedRequiredMargin)
      ? 0
      : convertedRequiredMargin.toFixed(2);
  };

  //#region API CALLS

  const openPendingOrder_api = async (margin) => {
    try {
      const currentDate = new Date();
      const currentDateTime = new Date(currentDate);
      // const exp = new Date(expiresAt);
      // const expdt = new Date(exp);

      const data: Order = {
        id: -1,
        created_at: null,
        symbol: selectedAuthSymbol,
        quantity: orderQuantity,
        amount: amount,
        asset_type: assetState.type,
        direction: direction,
        entry_price: orderEntryPrice,
        TP: takeProfit,
        SL: stopLoss,
        netEUR: 0, // Set appropriate values
        status: "",
        userId: user.userId,
        margin: margin,
        exit_price: 0,
        totalUnrealizedPnL: metrics.totalUnrealizedPnL,
        order_id: "OID" + Math.floor(100000 + Math.random() * 900000),
        status_updated_at: null,
        expires_at: expiresAt ? expiresAt : null,
        comment: orderComment,
      };

      const response = await APIMiddleware.post(API_ENDPOINT_OPEN_ORDER, data);

      const data_m = {
        ...response.data,
        created_at: new Date(response.data.created_at).toLocaleString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }),
      };

      // Check if the response data matches the Position interface
      //if (Object.keys(response.data).every((key) => key in data)) {
      // openOrder(data_m);
      sendDataToServer(4);
      setIsOrderOpening(false);
      // openPosition(data_m);
      //}

      // Show a success notification
      toast.success("Order opened successfully!", { position: "top-right" });
      document.getElementById("openSound").play();
      localStorage.accountManager = "open-orders-acc";
    } catch (error) {
      // Handle API request error
      console.error(`API request error: ${API_ENDPOINT_OPEN_ORDER}`, error);
      setIsOrderOpening(false);
    }
  };

  //#endregion

  //show spinner while loading data
  if (loadingSymbolContext) {
    return <Spinner />;
  }

  return (
    <>
      <div className="price-box-container">
        <div
          className={`price-box price-box-buy ${
            buyActive ? "price-box-buy-active" : ""
          }`}
          id="buyMarketOrder"
          onClick={() => handleBuySellOrderClick(true)}
        >
          <div>Buy</div>
        </div>
        <div
          className={`price-box price-box-sell ${
            sellActive ? "price-box-sell-active" : ""
          }`}
          id="sellMarketOrder"
          onClick={() => handleBuySellOrderClick(false)}
        >
          <div>Sell</div>
        </div>
      </div>
      <div className="css-flex">
        <div className="box">
          <label htmlFor="stop-loss-market" className="order-label">
            Entry Price:
          </label>
          <input
            type="number"
            id="order-entry-price"
            className="order-input"
            value={orderEntryPrice}
            onChange={(e) => setOrderEntryPrice(parseFloat(e.target.value))}
          />
        </div>
        {assetState.type === "base_asset" ? (
          <div className="box">
            <label htmlFor="take-profit-market" className="order-label">
              Quantity:
            </label>
            <input
              type="number"
              id="quantity"
              className="order-input"
              value={orderQuantity}
              // onChange={(e) => setOrderQuantity(parseFloat(e.target.value))}
              onChange={(e) => {
                setOrderQuantity((prev) => {
                  return parseFloat(e.target.value);
                });
              }}
            />
          </div>
        ) : (
          <div className="box">
            <label htmlFor="take-profit-market" className="order-label">
              Amount:
            </label>
            <input
              type="number"
              className="order-input"
              id="amount-input"
              value={amount}
              onChange={(e) => {
                setAmount(parseFloat(e.target.value));
              }}
            />
          </div>
        )}
        <div className="box">
          <label className="order-label">Type:</label>
          <select
            className="asset-select"
            name=""
            id=""
            value={assetState.name}
            onChange={handleAssetChange}
          >
            <option value={symbolInfo.base_asset}>
              {symbolInfo.base_asset}
            </option>
            <option value={symbolInfo.quote_asset}>
              {symbolInfo.quote_asset}
            </option>
          </select>
        </div>
      </div>
      <div className="reuqired-margin">
        <div className="title">Required Margin</div>
        <div className="value">
          {calculateOrderRequiredMargin()}{" "}
          {user?.userCurrencyName ||"USD"}
        </div>
      </div>
      {/* <div className="check-box-expiry"> */}
        {/* <label htmlFor="scss-checkbox" aria-checked="false">
          <div className="custom-box"></div>
          <p>Expiry</p>
        </label> */}
        {/* <input
          type="checkbox"
          id="scss-checkbox"
          hidden
          onChange={(e) => expiryDate(e)}
        /> */}
        {/* <input
          type="datetime-local"
          id="date-time"
          value={expiresAt}
          // onChange={handleDateTimeChange}
          onChange={(e) => handleDateTimeChange(e.target.value)}
          disabled={!expiresAtStatus}
        />

        {/* <DatePicker
          selected={expiresAt}
          onChange={(e) => handleDateTimeChange(e)}
          // dateFormat="yyyy/MM/dd" // customize date format as needed
          // dateFormat="MMMM d, yyyy h:mm aa"
          timeInputLabel="Time:"
          dateFormat="MM/dd/yyyy h:mm aa"
          showTimeInput
          calendarClassName="expiry-input"
          disabled={!expiresAtStatus}
          placeholderText="yyyy/MM/dd"
        /> */}
      {/* </div> */}
      {selectedOrderTab === "pending-order"?
      <SLTP handleProfitChange={handleProfitChange} handleLossChange={handleLossChange} direction={direction} quantity={orderQuantity} entryPrice={orderEntryPrice} />
     : <></>}
      <div className="text-bx">
        <div className="text-area-letter-counter-sltp">
          <span id="count-comment-letters">0</span>/100
        </div>
        <textarea
          className="comment-sltp-lst"
          placeholder="Comment"
          onInput={(e) => {
            countLetters(e);
          }}
          value={orderComment}
          onChange={(e) => {
            setOrderComment(e.target.value);
          }}
        ></textarea>
      </div>

      <div className="place-order">
        {/* Conditionally set the disabled attribute based on the user's authentication status */}
        <button
          disabled={!bidPrice ||!askPrice &&isOrderOpening}
          className={`place-order-button ${
            buyActive ? "place-order-button-buy" : "place-order-button-sell"
          }`}
          onClick={(e) => {
            placeOrder();
            mkRipple(e);
          }}
        >
          <b>{!isOrderOpening ? direction : "Loading..."}</b> <br />
          {orderQuantity || ""} {selectedAuthSymbol} @ {orderEntryPrice}
        </button>
      </div>
    </>
  );
};

export default PendingOrder;
