export const formatPrice = (price) => {
    if (price == null) {
        return '';  
    }

    // Convert the price to a string
    const priceString = price.toString();

    // Calculate the maximum number of digits allowed for the integer and decimal parts
    const totalMaxDigits = 10;

    // Calculate the maximum number of digits for the integer part, prioritizing it
    const maxIntegerDigits = Math.min(totalMaxDigits, priceString.indexOf('.') > -1 ? priceString.indexOf('.') : totalMaxDigits);

    // Calculate the maximum number of digits for the decimal part
    const maxDecimalDigits = totalMaxDigits - maxIntegerDigits;

    // Split the string into parts before and after the decimal point
    const [integerPart, decimalPart] = priceString.split('.');

    // Include all digits from the integer part
    const truncatedInteger = integerPart.slice(0, maxIntegerDigits);

    // If there is a decimal part, include remaining digits from it
    const truncatedDecimal = decimalPart ? decimalPart.slice(0, maxDecimalDigits) : '';

    // Concatenate the truncated integer and decimal parts
    const formattedPrice = truncatedDecimal ? `${truncatedInteger}.${truncatedDecimal}` : truncatedInteger;

    return formattedPrice;
};

export const formatDigitBasePrice = (price, digits) => {
    if (price == null) {
        return '';  
    }

    // Convert the price to a string
    const priceString = price.toString();

    // Calculate the maximum number of digits allowed for the integer and decimal parts
    const totalMaxDigits = digits;

    // Calculate the maximum number of digits for the integer part, prioritizing it
    const maxIntegerDigits = Math.min(totalMaxDigits, priceString.indexOf('.') > -1 ? priceString.indexOf('.') : totalMaxDigits);

    // Calculate the maximum number of digits for the decimal part
    const maxDecimalDigits = totalMaxDigits - maxIntegerDigits;

    // Split the string into parts before and after the decimal point
    const [integerPart, decimalPart] = priceString.split('.');

    // Include all digits from the integer part
    const truncatedInteger = integerPart.slice(0, maxIntegerDigits);

    // If there is a decimal part, include remaining digits from it
    const truncatedDecimal = decimalPart ? decimalPart.slice(0, maxDecimalDigits) : '';

    // Concatenate the truncated integer and decimal parts
    const formattedPrice = truncatedDecimal ? `${truncatedInteger}.${truncatedDecimal}` : truncatedInteger;

    return formattedPrice;
};

export const formatPriceUptoDecimals = (price, decimalDigits = 2) => {
    if (price == null) {
        return '';
    }

    const priceString = price.toString();

    const [integerPart, decimalPart] = priceString.split('.');
    let truncatedDecimal = '';
    if (decimalPart) {
        truncatedDecimal = decimalPart.slice(0, decimalDigits);
    }

    const formattedPrice = truncatedDecimal ? `${integerPart}.${truncatedDecimal}` : integerPart;

    return formattedPrice;
};

export const formatPositionToPipSize = (pipPosition) => {
    if(pipPosition == null){
        return 1;
    }

    return 1 / Math.pow(10, pipPosition);
  }  
