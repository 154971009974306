import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import $ from "jquery";
import Spinner from "../../utils/spinner/Spinner";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5stock from "@amcharts/amcharts5/stock";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import am5themes_Default from "@amcharts/amcharts5/themes/Responsive";
import {  API_ENDPOINT_KLINES,API_ENDPOINT_KLINES_MT } from "../../../data/Endpoints-API";
import { useSymbolContext } from "../../../contexts/Symbol-Context";
import { useAuthContext } from "../../../contexts/Auth-Context";
import { useChartContext } from "../../../contexts/Chart-Context";
import { useThemeContext } from "../../../contexts/Theme-Context";
import "./Chart.scss";
import "./Chart-Toolbar.scss";
import { useAccountManagerContext } from "../../../contexts/Account-Manager-Context.js";
import { formatPriceUptoDecimals } from "../../../utils/format";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";


const Chart = () => {
  const { openPositions, closedPositions } = useAccountManagerContext();
  let preY = 0;
  window.addEventListener("mousemove", (event) => {
    const nowY = event.clientY;
    if (nowY < preY) {
      // console.log('mouse up');
    } else if (nowY > preY) {
      // console.log('mouse down');
    }
    preY = nowY;
  });

  // Context
  const { theme } = useThemeContext();
  const {
    loadingSymbolContext,
    symbolNames,
    updateSymbolName,
    symbolInfo,
    updateSymbolDetailsData,
    selectedCategoryId,
    setSelectedCategoryId,
    symbolWithCategories,
    selectedSymbolSession,
    setSelectedSymbolSession
  } = useSymbolContext();
  const { user, platFromData,selectedAuthSymbol, setAuthSelectedSymbol, setAuthTimeFrame, selectedAuthTimeFrame, setAuthSelectedCategory,authSelectedCategory } = useAuthContext();
  const { selectedTimeFrame, updateChartTimeFrame, selectedSeriesType, setSelectedSeriesType, zoomRange, lineCandle, setLineCandle, riseFromOpenColor, setRiseFromOpenColor, dropFromOpenColor, setDropFromOpenColor } = useChartContext();

  useEffect(() => {
    if (selectedAuthSymbol !== '') {
      updateSymbolDetailsData(selectedAuthSymbol);
      // setTimeout(() => {
      //   document.getElementsByClassName("am5stock")[4].click();
      //   document.getElementsByClassName("am5stock")[7].click();
      // }, 5000);
    }
  }, [selectedAuthSymbol])

  //local states
  // let [stockChart, setStockChart] = useState(null);
  let [stockChart2, setStockChart2] = useState(null);
  let [numberFormat, setnumberFormat] = useState("#,###.00");
  let [timeFrameChartSetup, setTimeFrameChartSetup] = useState("minute");
  // let [currentLabel, setCurrentLabel] = useState(null);
  let [currentLabel2, setCurrentLabel2] = useState(null);
  // let [currentValueDataItem, setCurrentValueDataItem] = useState(null);
  let [currentValueDataItem2, setCurrentValueDataItem2] = useState(null);

  let root = null;
  let stockChart = null;
  let currentLabel = null;
  let currentValueDataItem = null;

  // useEffect to handle component lifecycle
  useEffect(() => {
    //nothing will load in this component until SymbolContext fully loaded
    if (!loadingSymbolContext) {
      // Add the 'hidden-label' class to labels to hide on mount
      // $(document).ready(() => {
      //   $(
      //     '.am5stock-control-label:contains("Candles"),' +
      //     '.am5stock-control-label:contains("Indicators"),' +
      //     '.am5stock-control-label:contains("Draw")'
      //   ).addClass("hidden");
      // });
      chartSetup();
      // setStockChart2(stockChart);
      // setCurrentLabel2(currentLabel);
      // setCurrentValueDataItem2(currentValueDataItem);
    }
  }, [loadingSymbolContext, selectedAuthSymbol, selectedTimeFrame, zoomRange, theme, riseFromOpenColor, dropFromOpenColor, selectedSeriesType, localStorage.positionMarker, localStorage.chartTooltips]);

  // useEffect(() => {
  //   let upPriceColor = getComputedStyle(document.getElementById("chartUpColor")).backgroundColor;
  //   let downPriceColor = getComputedStyle(document.getElementById("chartDownColor")).backgroundColor;
  //   setRiseFromOpenColor(upPriceColor);
  //   setDropFromOpenColor(downPriceColor);
  // }, [theme]);



  useEffect(() => {
    //nothing will load in this component until SymbolContext fully loaded
    if (!loadingSymbolContext ) {
      // setTimeout(() => {
      updateLiveFeedData();


      // stockChart.set("volumeSeries", volumeSeries);

      // }, 10000); 
    }
  }, [platFromData[1]]);

  //dispose root if there is any because only root could exists
  const maybeDisposeRoot = (divId) => {
    am5.array.each(am5.registry.rootElements, function (root) {
      if (root.dom.id == divId) {
        root.dispose();
      }
    });
  };

  // Main chart setup function
  const chartSetup = () => {

    //dispose root if there is any because only root could exists
    maybeDisposeRoot('chartdiv');

    //#region CHART CONFIGURATION----------------------------------------------------------------------------------------------------------------------
    root = am5.Root.new('chartdiv');

    //custom theme
    const myTheme = am5.Theme.new(root);

    myTheme.rule("Grid").setAll({
      stroke: am5.color(0x808080),
      strokeWidth: 1,
    });

    myTheme.rule("Label").setAll({
      fill: am5.color(0x808080),
    });

    // Set themes
      root.setThemes([
        am5themes_Animated.new(root),
        am5themes_Dark.new(root),
        myTheme,
      ]);

    // Create a stock chart
    stockChart = root.container.children.push(
      am5stock.StockChart.new(root, {
        stockPositiveColor: am5.color('rgb(17, 209, 97)'),
        stockNegativeColor: am5.color('rgb(245, 36, 36)'),
      })
    );

    setStockChart2(stockChart);

    // Set global number format
    root.numberFormatter.set("numberFormat", numberFormat);

    // Create a main stock panel (chart)
    let mainPanel = stockChart.panels.push(
      am5stock.StockPanel.new(root, {
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        pinchZoomX: true,
        pinchZoomY: true,
      })
    );

    // Create value/vertical axis
    let valueAxis = mainPanel.yAxes.push(
      am5xy.ValueAxis.new(root, {
        start: 0.9, //show 10% candles on load
        renderer: am5xy.AxisRendererY.new(root, {
          pan: "zoom",
        }),
        extraMin: 0.1, // adds some space for for main series
        extraMax: 0.4, // adds some space for for main series
        tooltip: am5.Tooltip.new(root, {}),
        numberFormat: numberFormat,
        extraTooltipPrecision: 2,
      })
    );


    // Create date/horizontal axis
    let dateAxis = mainPanel.xAxes.push(
      am5xy.GaplessDateAxis.new(root, {
        start: zoomRange, //show 10% candles on load
        baseInterval: {
          timeUnit: timeFrameChartSetup,
          count: 1,
        },
        renderer: am5xy.AxisRendererX.new(root, {
          pan: "zoom",
        }),
        tooltip: am5.Tooltip.new(root, {}),
        end: 1.1, // increase the end value to add space on the right side
      })
    );

    // add range which will show current value
    currentValueDataItem = valueAxis.createAxisRange(
      valueAxis.makeDataItem({ value: 0 })
    );

    setCurrentValueDataItem2(currentValueDataItem);
    currentLabel = currentValueDataItem.get("label");
    setCurrentLabel2(currentLabel);
    if (currentLabel) {
      currentLabel.setAll({
        fill: am5.color(0xffffff),
        background: am5.Rectangle.new(root, { fill: am5.color(0x000000) }),
      });
    }


    let currentGrid = currentValueDataItem.get("grid");
    if (currentGrid) {
      currentGrid.setAll({ strokeOpacity: 0.5, strokeDasharray: [2, 5] });
    }

    // Add series
    let valueSeries;
    var tooltip;
    const textColor = getComputedStyle(document.querySelector('#textBoldColor')).color;

    const chartTooltips = () => {
      tooltip = am5.Tooltip.new(root, {
        pointerOrientation: "up",
        getFillFromSprite: false,
        getStrokeFromSprite: false,
        autoTextColor: false,
        getLabelFillFromSprite: true,
        labelHTML: `<p style="color:${textColor};" >Open: {openValueY} </br> Low: {lowValueY} </br> High: {highValueY} </br> Close: {valueY}</p>`,
      });
      tooltip.get("background").setAll({
        fill: getComputedStyle(document.querySelector('.left-nav')).backgroundColor,
        stroke: false,
      });
    }

    // if (localStorage.chartTooltips === 'show') {
    //   chartTooltips();
    // }

    // if (selectedSeriesType == 'line') {
    //   valueSeries = mainPanel.series.push(
    //     am5xy.LineSeries.new(root, {
    //       name: selectedAuthSymbol,
    //       clustered: false,
    //       valueXField: "Date",
    //       valueYField: "Close",
    //       highValueYField: "High",
    //       lowValueYField: "Low",
    //       openValueYField: "Open",
    //       calculateAggregates: true,
    //       xAxis: dateAxis,
    //       yAxis: valueAxis,
    //       tooltip: tooltip
    //     })
    //   );
    // }
    // else if (selectedSeriesType == 'ohlc') {
    //   valueSeries = mainPanel.series.push(
    //     am5xy.OHLCSeries.new(root, {
    //       name: selectedAuthSymbol,
    //       clustered: false,
    //       valueXField: "Date",
    //       valueYField: "Close",
    //       highValueYField: "High",
    //       lowValueYField: "Low",
    //       openValueYField: "Open",
    //       calculateAggregates: true,
    //       xAxis: dateAxis,
    //       yAxis: valueAxis,
    //       tooltip: tooltip
    //     })
    //   );
    // }
    // else if(selectedSeriesType == 'procandlestick'){
    //   valueSeries = mainPanel.series.push(
    //     am5xy.CandlestickSeries.new(root, {
    //       name: selectedAuthSymbol,
    //       clustered: false,
    //       valueXField: "Date",
    //       valueYField: "Close",
    //       highValueYField: "High",
    //       lowValueYField: "Low",
    //       openValueYField: "Open",
    //       calculateAggregates: true,
    //       xAxis: dateAxis,
    //       yAxis: valueAxis,
    //       tooltip: tooltip
    //     })

    //   );
    //   valueSeries.columns.template.get("themeTags").push("pro");
    // }else {
      valueSeries = mainPanel.series.push(
        am5xy.CandlestickSeries.new(root, {
          name: selectedAuthSymbol,
          clustered: false,
          valueXField: "Date",
          valueYField: "Close",
          highValueYField: "High",
          lowValueYField: "Low",
          openValueYField: "Open",
          calculateAggregates: true,
          xAxis: dateAxis,
          yAxis: valueAxis,
          tooltip: tooltip
        })
      );
    // }

    // setTimeout(() => {
    //   if (lineCandle !== 'line') {
    //     valueSeries.columns.template.states.create("riseFromOpen", {
    //       fill: am5.color('rgb(17, 209, 97)'),
    //       stroke: am5.color('rgb(17, 209, 97)')
    //     });

    //     valueSeries.columns.template.states.create("dropFromOpen", {
    //       fill: am5.color('rgb(245, 36, 36)'),
    //       stroke: am5.color('rgb(245, 36, 36)')
    //     });
    //   }
    // }, 1000);

    // var valueTooltip = valueSeries.set("tooltip", am5.Tooltip.new(root, {
    //   getFillFromSprite: false,
    //   getStrokeFromSprite: true,
    //   getLabelFillFromSprite: true,
    //   autoTextColor: false,
    //   pointerOrientation: "horizontal",
    //   labelText: "{name}: {valueY} {valueYChangePreviousPercent.formatNumber('[#00ff00]+#,###.##|[#ff0000]#,###.##|[#999999]0')}%"
    // }));
    // valueTooltip.get("background").set("fill", root.interfaceColors.get("background"));
    // valueTooltip.get("background").set("fill", am5.color('#ffffff'));
    // valueTooltip.get("background").setAll({
    //   fill: am5.color(0xffffff),
    //   fillOpacity: 0.8
    // });

    // Set main value series
    stockChart.set("stockSeries", valueSeries);
    stockChart.set("volumeSeries", volumeSeries);

    // Add a stock legend
    // let valueLegend = mainPanel.plotContainer.children.push(
    //   am5stock.StockLegend.new(root, {
    //     stockChart: stockChart,
    //   })
    // );

    // Set main series
    // valueLegend.data.setAll([valueSeries]);

    // Add cursor(s)
    mainPanel.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        // behavior: "zoomXY",
        // behavior: "selectXY",
        yAxis: valueAxis,
        xAxis: dateAxis,
        //snapToSeries: [valueSeries],
        //snapToSeriesBy: "y!"
      })
    );
    const bgColor = getComputedStyle(document.body).backgroundColor;

    var scrollbarX = am5xy.XYChartScrollbar.new(root, {
      orientation: "horizontal",
      width: 100,
      x: am5.p100,
      centerX: am5.p100,
      dx: -65,
      y: 8,
      centerY: am5.p100,
    });

    scrollbarX.thumb.setAll({
      fill: bgColor,
      fillOpacity: 0
    });

    scrollbarX.startGrip.setAll({
      visible: true,
      scale: 0.6
    });

    scrollbarX.endGrip.setAll({
      visible: true,
      scale: 0.6
    });

    scrollbarX.get("background").setAll({
      fill: bgColor,
      fillOpacity: 0,
      cornerRadiusTR: 100,
      cornerRadiusBR: 100,
      cornerRadiusTL: 100,
      cornerRadiusBL: 100
    });

    mainPanel.set("scrollbarX", scrollbarX);

    // Create volume axis
    // -------------------------------------------------------------------------------
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    var volumeAxisRenderer = am5xy.AxisRendererY.new(root, {
      inside: true
    });

    volumeAxisRenderer.labels.template.set("forceHidden", true);
    volumeAxisRenderer.grid.template.set("forceHidden", true);

    var volumeValueAxis = mainPanel.yAxes.push(am5xy.ValueAxis.new(root, {
      numberFormat: "#.#a",
      height: am5.percent(20),
      y: am5.percent(100),
      centerY: am5.percent(100),
      renderer: volumeAxisRenderer
    }));

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    var volumeSeries = mainPanel.series.push(am5xy.ColumnSeries.new(root, {
      name: "Volume",
      clustered: false,
      valueXField: "Date",
      valueYField: "Volume",
      xAxis: dateAxis,
      yAxis: volumeValueAxis,
      legendValueText: "[bold]{valueY.formatNumber('#,###.0a')}[/]"
    }));

    volumeSeries.columns.template.setAll({
      strokeOpacity: 0,
      fillOpacity: 0.2
    });

    // color columns by stock rules
    volumeSeries.columns.template.adapters.add("fill", function (fill, target) {
      return am5.color(getComputedStyle(document.querySelector('.left-nav')).color);
    })
   

    // Load initial data for the first series
    try {
      // loadChartData([valueSeries], dateAxis);
      loadChartData(valueSeries, dateAxis);
    } catch (error) {
      console.error("Error loading chart data:", error);
      console.log(error)
    }

    // Set up symbol searchable drop down list
    let symbolSearchList = am5stock.DropdownListControl.new(root, {
      stockChart: stockChart,
      name: selectedAuthSymbol,
      fixedLabel: true,
      searchable: true,
      items: symbolNames,
    });

    symbolSearchList.events.on("selected", function (ev) {
      // An item selected
      symbolWithCategories.map((symbol) => {
        if (symbol.name === ev.item.label) {
          setSelectedCategoryId(symbol.symbol_category);
          setAuthSelectedCategory(symbol.symbol_category);
          setSelectedSymbolSession(symbol.is_session_active);
        }
      })
      updateSymbolName(ev.item.label);
      setAuthSelectedSymbol(ev.item.label)
      // updateSymbolDetailsData(ev.item.label);
    });

    // Set up series type switcher
    let seriesSwitcher = am5stock.SeriesTypeControl.new(root, {
      stockChart: stockChart,
    });

    seriesSwitcher.events.on("selected", function (ev) {
      setSeriesType(ev.item.id);
      setLineCandle(ev.item.id);
      const svg = new XMLSerializer().serializeToString(ev.item.icon);
      const chartData = { type: ev.item.id, icon: svg };
      const storeable = JSON.stringify(chartData);
      localStorage.setItem('chartData', storeable);
      setTimeout(() => {
        const seriesIcon = document.getElementsByClassName('am5stock-control-icon')[1];
        // seriesIcon.innerHTML = JSON.parse(localStorage.chartData).icon;
      }, 1000);
    });

    function getNewSettings(series) {
      let newSettings = [];
      am5.array.each(
        [
          "name",
          "valueYField",
          "highValueYField",
          "lowValueYField",
          "openValueYField",
          "calculateAggregates",
          "valueXField",
          "xAxis",
          "yAxis",
          "legendValueText",
          "stroke",
          "fill",
        ],
        function (setting) {
          newSettings[setting] = series.get(setting);
        }
      );
      return newSettings;
    }

    function setSeriesType(seriesType) {
      // Get current series and its settings
      let currentSeries = stockChart.get("stockSeries");
      let newSettings = getNewSettings(currentSeries);

      // Remove previous series
      let data = currentSeries.data.values;
      mainPanel.series.removeValue(currentSeries);

      // Create new series
      let series;
      let selectedSeriesType;
      switch (seriesType) {
        case "line": {
          series = mainPanel.series.push(
            am5xy.LineSeries.new(root, newSettings)
          );
          selectedSeriesType = seriesType;
          // setSelectedSeriesType('line');
          break;
        }
        case "candlestick": {
          newSettings.clustered = false;
          series = mainPanel.series.push(
            am5xy.CandlestickSeries.new(root, newSettings)
          );
          selectedSeriesType = seriesType;
          
          // setSelectedSeriesType('candlestick');
          break;
        }
        case "procandlestick":
          {
            newSettings.clustered = false;
            series = mainPanel.series.push(
              am5xy.CandlestickSeries.new(root, newSettings)
            );
            if (seriesType == "procandlestick") {
              series.columns.template.get("themeTags").push("pro");
            }
          selectedSeriesType = seriesType;

            // setSelectedSeriesType('procandlestick');
            break;
          }
        case "ohlc":
          {
            newSettings.clustered = false;
            series = mainPanel.series.push(
              am5xy.OHLCSeries.new(root, newSettings)
            );
          selectedSeriesType = seriesType;

            // setSelectedSeriesType('ohlc');
            break;
          }
      }

      // Set new series as stockSeries
      if (series) {
        // valueLegend.data.removeValue(currentSeries);
        series.data.setAll(data);
        stockChart.set("stockSeries", series);
        // let cursor = mainPanel.get("cursor");
        // if (cursor) {
        //   cursor.set("snapToSeries", [series]);
        // }
        // valueLegend.data.insertIndex(0, series);
      }
    }

    //set intervals...
    let intervalSwitcher = am5stock.IntervalControl.new(root, {
      stockChart: stockChart,
      currentItem: selectedTimeFrame,
      items: [
        { id: "1m", label: "1m", interval: { timeUnit: "minute", count: 1 } },
        { id: "5m", label: "5m", interval: { timeUnit: "minute", count: 5 } },
        {
          id: "15m",
          label: "15m",
          interval: { timeUnit: "minute", count: 15 },
        },
        {
          id: "30m",
          label: "30m",
          interval: { timeUnit: "minute", count: 30 },
        },
        { id: "1h", label: "1h", interval: { timeUnit: "hour", count: 1 } },
        { id: "4h", label: "4h", interval: { timeUnit: "hour", count: 4 } },
        { id: "1d", label: "1d", interval: { timeUnit: "day", count: 1 } },
        // { id: "1w", label: "1w", interval: { timeUnit: "week", count: 1 } },
        // { id: "1M", label: "1M", interval: { timeUnit: "month", count: 1 } },
        // { id: "1Y", label: "1Y", interval: { timeUnit: "year", count: 1 } }
      ],
    });

    const wiseWatcher = setInterval(() => {
      console.log("wise watcher");
      if (document.querySelector(".am5stock") != null) {
        clearInterval(wiseWatcher);
        let controlList = document.getElementsByClassName(
          "am5stock-control-list"
        )[2];
        for (let index = 0; index < controlList.children.length; index++) {
          if (
            controlList.children[index].getAttribute("title") ==
            selectedTimeFrame
          ) {
            controlList.children[index].setAttribute("role", "selected");
          }
        }
      }
    }, 500);

    intervalSwitcher.events.on("selected", function (ev) {
      updateChartTimeFrame(ev.item.id);
      setAuthTimeFrame(ev.item.id);
      localStorage.setItem('timeFrame', ev.item.id);
    });

    // Stock toolbar
    let toolbar = am5stock.StockToolbar.new(root, {
      container: document.getElementById("chartcontrols"),
      stockChart: stockChart,
      controls: [
        symbolSearchList,
        seriesSwitcher,
        // am5stock.SeriesTypeControl.new(root, {
        //     stockChart: stockChart
        // }),
        intervalSwitcher,
        am5stock.IndicatorControl.new(root, {
          stockChart: stockChart,
          // legend: valueLegend,
        }),
        am5stock.DrawingControl.new(root, {
          stockChart: stockChart,
        }),
        am5stock.ResetControl.new(root, {
          stockChart: stockChart,
        }),
        am5stock.SettingsControl.new(root, {
          stockChart: stockChart,
        }),
      ],
    });

    // root.autoResize = true;
  };
 


  // Function that dynamically loads data
  const loadChartData = (series, dateAxis) => {
    // Load external data
    let data;
      am5.net
        .load(API_ENDPOINT_KLINES_MT(selectedAuthSymbol, selectedTimeFrame,user?.userId))
        // .load(API_ENDPOINT_FMP_KLINES(selectedAuthSymbol, "5min"))
        .then(function (result) {

      // Set data on all series of the chart
      const res = am5.JSONParser.parse(result.response);
          const data = res.data;

      const chartData = data.map((item) => {
        // console.log(item);
        // return authSelectedCategory == 1 ? {
        //   Date: new Date(item[0]).getTime(), // Assuming the timestamp is in milliseconds
        //   Open: parseFloat(item[1]),
        //   High: parseFloat(item[2]),
        //   Low: parseFloat(item[3]),
        //   Close: parseFloat(item[4]),
        //   Volume: parseFloat(item[5]),
        // } : 
        return {
          Date: new Date(item.time).getTime(), // Assuming the timestamp is in milliseconds
          Open: parseFloat(item.open),
          High: parseFloat(item.high),
          Low: parseFloat(item.low),
          Close: parseFloat(item.close),
          Volume: parseFloat(item.volume), // This seems to be a typo, should it be parseFloat(item.volume) instead?
        };
      });

          // Set data
          // am5.array.each(series, function (item) {
          //   item.data.setAll(chartData);
          // });
          series.data.setAll(chartData);
        });
    
  };

  // update price chart with live feeds
  const updateLiveFeedData = () => {
  
    if (platFromData.length > 0 && platFromData[1] && platFromData[1] != undefined && platFromData[1] != 'undefined' && stockChart2 != null && currentLabel2 != null && currentValueDataItem2 != null && platFromData[1].s== selectedAuthSymbol) {
      // Create a new data point based on the live feed data

      const liveFeedData = {
        Date: new Date(platFromData[1].t).getTime(), // Assuming the timestamp is in milliseconds
        Open: parseFloat(platFromData[1].o),
        High: parseFloat(platFromData[1].h),
        Low: parseFloat(platFromData[1].l),
        Close: parseFloat(platFromData[1].c),
        Volume: parseFloat(platFromData[1].v),
      };

      // Get the series for candlestick chart
      // console.log('valueSeries', stockChart2);
      let valueSeries = stockChart2.get("stockSeries");
      // console.log("st", valueSeries, liveFeedData);

      // Get the current timestamp
      let date = liveFeedData.Date; //Date.now();

      // Variable to store the new value for the live feed
      let value;
      let volume;

      // Get the last data object in the series
      let lastDataObject = valueSeries.data.getIndex(valueSeries.data.length - 1);
      if (lastDataObject) {
        // Get the previous date and closing value from the last data object
        let previousDate = lastDataObject.Date;
        let previousValue = lastDataObject.Close;

        // set live price value
        value = liveFeedData.Close;
        volume = liveFeedData.Volume;

        // Get the high, low, and open values from the last data object
        let high = lastDataObject.High;
        let low = lastDataObject.Low;
        let open = lastDataObject.Open;

        // Check if a minute has passed since the previous data point
        if (am5.time.checkChange(date, previousDate, timeFrameChartSetup)) {
          open = value;
          high = value;
          low = value;

          let dObj1 = {
            Date: date,
            Close: value,
            Open: value,
            Low: value,
            High: value,
            Volume: volume,
          };

          // Add the new data object to the series
          valueSeries.data.push(dObj1);

          previousDate = date;
        } else {
          if (value > high) {
            high = value;
          }

          if (value < low) {
            low = value;
          }

          // Update the existing data object for the current minute
          let dObj2 = {
            Date: date,
            Close: value,
            Open: open,
            Low: low,
            High: high,
            Volume: volume,
          };

          // Replace the last data object in the series with the updated data
          valueSeries.data.setIndex(valueSeries.data.length - 1, dObj2);
        }
        // Update the live data label and animation if available
        if (currentLabel2) {
          currentValueDataItem2.set("value", value);
          currentLabel2.set("text", formatPriceUptoDecimals(value, symbolInfo.digit));
          let bg = currentLabel2.get("background");
          if (bg) {
            if (value < open) {
              bg.set("fill", am5.color('rgb(245, 36, 36)'));
            } else {
              bg.set("fill", am5.color('rgb(17, 209, 97)'));
            }
          }
        }

      }
    }
  };
  //show spinner while loading data
  if (loadingSymbolContext) {
    return <Spinner />;
  }

  return (
    <div className="Chart">
      <div id="chartcontrols"></div>
      <div id="chartdiv"></div>
    </div>
  );
};

export default Chart;
