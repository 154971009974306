import React, { useEffect, useState } from 'react';
import { useMetricsContext } from '../../../contexts/Metrics-Context';
import { useAuthContext } from "../../../contexts/Auth-Context";
import './Metrics-Panel.scss';

const MetricsPanel = () => {
    if (localStorage.metricsToggle === undefined) {
        localStorage.setItem('metricsToggle', true);
    }
    const [metrixSettings, setMetrixSettings] = useState(false);
    const [showBalance, setShowBalance] = useState(JSON.parse(localStorage.metricsToggle).showBalance === false ? false : true);
    const [showEquity, setShowEquity] = useState(JSON.parse(localStorage.metricsToggle).showEquity === false ? false : true);
    const [showMargin, setShowMargin] = useState(JSON.parse(localStorage.metricsToggle).showMargin === false ? false : true);
    const [showFreeMargin, setShowFreeMargin] = useState(JSON.parse(localStorage.metricsToggle).showFreeMargin === false ? false : true);
    const [showBonus, setShowBonus] = useState(false);
    // const [showBonus, setShowBonus] = useState(JSON.parse(localStorage.metricsToggle).showBonus === false ? false : true);
    // const [showMarginLevel, setShowMarginLevel] = useState(JSON.parse(localStorage.metricsToggle).showMarginLevel === false ? false : true);
    const [showMarginLevel, setShowMarginLevel] = useState(false);
    const [showUNRPNL, setShowUNRPNL] = useState(JSON.parse(localStorage.metricsToggle).showUNRPNL === false ? false : true);
    const [expandView, setExpandView] = useState(JSON.parse(localStorage.metricsToggle).expandView === false ? false : true);
    const metricsToggleObject = {
        showBalance: showBalance,
        showEquity: showEquity,
        showMargin: showMargin,
        showFreeMargin: showFreeMargin,
        showMarginLevel: showMarginLevel,
        showUNRPNL: showUNRPNL,
        expandView: expandView
    }
    const metricsToggleStringFormate = JSON.stringify(metricsToggleObject);
    localStorage.setItem('metricsToggle', metricsToggleStringFormate);

    // Access metrics context
    const { metrics } = useMetricsContext();
    const { user } = useAuthContext();

    // check if a value is a valid number
    const isValidNumber = (value) => typeof value === 'number' && !isNaN(value);

    return (
        <div className="metrics-panel" >
            <ul className="metrics-list" aria-details={expandView} onDoubleClick={() => { setExpandView(expandView === true ? false : true) }} >
                <li className='metricsData' aria-selected={Object.values(metricsToggleObject).includes(true) === false ? true : false}>
                    <strong>Metrics Panel</strong>
                </li>
                <li className='metricsData' aria-selected={showBalance} >
                    <strong title='Balance = deposits - withdrawals + total realised net profit and loss' >Balance: <i>ℹ️</i></strong> <span>{isValidNumber(metrics?.balance) ? metrics.balance.toFixed(2) : 'N/A'} {user?.userCurrencyName || 'USD'}</span>
                </li>
                <li className='metricsData' aria-selected={showEquity}>
                    <strong title='Equity = balance + unrealised net profit and loss' >Equity: <i>ℹ️</i></strong> <span>{isValidNumber(metrics?.equity) ? metrics.equity.toFixed(2) : 'N/A'}</span>
                </li>
                <li className='metricsData' aria-selected={showMargin}>
                    <strong title='A margin sum of all open positions. Position margin is position volume divided by leverage and denominated in the account currency' >Margin: <i>ℹ️</i></strong> <span>{isValidNumber(metrics?.totalMargin) ? metrics.totalMargin.toFixed(2) : 'N/A'}</span>
                </li>
                <li className='metricsData' aria-selected={showFreeMargin}>
                    <strong title='Free margin = equity - margin used' >Free Margin: <i>ℹ️</i></strong> <span>{isValidNumber(metrics?.freeMargin) ? metrics.freeMargin.toFixed(2) : 'N/A'}</span>
                </li>
                <li className='metricsData' aria-selected={showBonus}>
                    <strong title="User's Bonus" >Bonus: <i>ℹ️</i></strong> <span>{isValidNumber(metrics?.totalBonus) ? metrics.totalBonus.toFixed(2) : 'N/A'}</span>
                </li>
                <li className='metricsData' aria-selected={showMarginLevel}>
                    <strong title='Margin level = (equity -  estimated closing commission) / margin used expressed as a percentage. When your margin level is equal to the stop out level, your positions get stopped out' >Margin Level: <i>ℹ️</i></strong> <span>{isValidNumber(metrics?.marginLevel) ? metrics.marginLevel.toFixed(2) + '%' : 'N/A'}</span>
                </li>
                <li className='metricsData' aria-selected={showUNRPNL}>
                    <strong title='Unrealised net = your unrealised profit and loss after expenses (commissions and swaps)' >UnR PNL: <i>ℹ️</i></strong> <span className={parseFloat(metrics?.totalUnrealizedPnL) >= 0 ? "positive-pnl" : "negative-pnl"}>{isValidNumber(metrics?.totalUnrealizedPnL) ? metrics.totalUnrealizedPnL.toFixed(2) : 'N/A'}</span>
                </li>
                {/* Add more items based on requirements */}
                <div className='metrix-settings' aria-busy={metrixSettings} >
                    <div className='focus-out-metrix' onClick={() => { setMetrixSettings(false) }} ></div>
                    <div className='toggle-metrix-data' >
                        <ul>
                            <li aria-selected={showBalance} onClick={() => { setShowBalance(showBalance === true ? false : true) }} >
                                <div className='toggle-indicator' >&#x2713;</div>
                                <p>Balance</p>
                            </li>
                            <li aria-selected={showEquity} onClick={() => { setShowEquity(showEquity === true ? false : true) }} >
                                <div className='toggle-indicator' >&#x2713;</div>
                                <p>Equity</p>
                            </li>
                            <li aria-selected={showMargin} onClick={() => { setShowMargin(showMargin === true ? false : true) }} >
                                <div className='toggle-indicator' >&#x2713;</div>
                                <p>Margin</p>
                            </li>
                            <li aria-selected={showFreeMargin} onClick={() => { setShowFreeMargin(showFreeMargin === true ? false : true) }} >
                                <div className='toggle-indicator' >&#x2713;</div>
                                <p>Free Margin</p>
                            </li>
                            {/* <li aria-selected={showMarginLevel} onClick={() => { setShowMarginLevel(showMarginLevel === true ? false : true) }} >
                                <div className='toggle-indicator' >&#x2713;</div>
                                <p>Margin Level</p>
                            </li> */}
                            <li aria-selected={showBonus} onClick={() => { setShowBonus(showBonus === true ? false : true) }} >
                                <div className='toggle-indicator' >&#x2713;</div>
                                <p>Bonus</p>
                            </li>
                            <li aria-selected={showUNRPNL} onClick={() => { setShowUNRPNL(showUNRPNL === true ? false : true) }} >
                                <div className='toggle-indicator' >&#x2713;</div>
                                <p>UnR PNL</p>
                            </li>
                            <li aria-selected={expandView} onClick={() => { setExpandView(expandView === true ? false : true) }} >
                                <div className='toggle-indicator' >&#x2713;</div>
                                <p>Expand View</p>
                            </li>
                        </ul>
                    </div>
                    <svg viewBox="0 0 13 12" onClick={() => { setMetrixSettings(true) }}>
                        <path d="M1.37083 3.3001L6.5 0.566574L11.6292 3.3001V8.69989L6.5 11.4334L1.37083 8.69989V3.3001Z" />
                        <circle cx="6.5" cy="6" r="1.65" stroke-width="1" />
                    </svg>
                </div>
            </ul>
        </div>
    );
};

export default MetricsPanel;
