//below are api endpoints different possible formates

//'http://server:port/endpoint';
//'http://server:port/endpoint?param1=value1';
//'http://server:port/endpoint?param1=value1&param2=value2';
//'http://server:port/endpoint?param1=value1&param2=value2&param3=value3';
//'http://server:port/endpoint?param1=value1&param2=value2&param3=value3&...';

const protocol = process.env.REACT_APP_IS_SECURED_PROTOCOL === 'true' ? 'https' : 'http';
const server = process.env.REACT_APP_DATA_SERVER;
const port = process.env.REACT_APP_DATA_SERVER_PORT;

const API_ROOT = port ? `${protocol}://${server}:${port}` : `${protocol}://${server}`;
//const API_ROOT = `${protocol}://${server}:${port}`;

//API Endpoints Names................................................................................................
const LOGIN = 'login';
const LOGOUT = 'logout';
const USER_DETAILS = 'user-details';
const OPEN_POSITION = 'open-position';
const CLOSE_POSITION = 'close-position';
const OPENED_POSITIONS = 'opened-positions';
const CLOSED_POSITIONS = 'closed-positions';
const SYMBOLS = 'symbols';
const KLINES = 'klines';
const KLINES_MT = 'match-trader-klines';
const FMP_KLINES = 'fmp-klines';
const LEVERAGE = 'leverage';
const SYMBOL_INFO = 'symbol-info';
const USER_WATCHLIST = 'user-watchlist';
const INSERT_WATCHLIST = 'insert-watchlist';
const DELETE_WATCHLIST = 'delete-watchlist';
const SESSION = 'session';
const OPEN_ORDER = 'open-order';
const CLOSE_ORDER = 'close-order';
const CANCEL_ORDER = 'cancel-order';
const OPENED_ORDERS = 'opened-orders';
const CLOSED_ORDERS = 'closed-orders';
const CLOSE_ALL_POSITIONS = 'close-all-positions';
const CLOSE_ALL_ORDERS = 'close-all-orders';
const SESSION_DETAIL = 'session-detail';
const POSITION_DETAIL = 'position-details';
const UPDATE_POSITION_DETAILS = 'update-position';

//API Endpoints URLs.................................................................................................
export const API_ENDPOINT_LOGIN = `${API_ROOT}/${LOGIN}`
export const API_ENDPOINT_LOGOUT = `${API_ROOT}/${LOGOUT}`
export const API_ENDPOINT_USER_DETAILS = `${API_ROOT}/${USER_DETAILS}`
export const API_ENDPOINT_OPEN_POSITION = `${API_ROOT}/${OPEN_POSITION}`
export const API_ENDPOINT_OPEN_ORDER = `${API_ROOT}/${OPEN_ORDER}`
export const API_ENDPOINT_CLOSE_POSITION = `${API_ROOT}/${CLOSE_POSITION}`
export const API_ENDPOINT_CLOSE_ORDER = `${API_ROOT}/${CLOSE_ORDER}`
export const API_ENDPOINT_CANCEL_ORDER = `${API_ROOT}/${CANCEL_ORDER}`
export const API_ENDPOINT_OPENED_POSITIONS = (userId) => (`${API_ROOT}/${OPENED_POSITIONS}?id=${userId}`)
export const API_ENDPOINT_CLOSED_POSITIONS = (userId) => (`${API_ROOT}/${CLOSED_POSITIONS}?id=${userId}`)
export const API_ENDPOINT_SYMBOLS = (userId) => (`${API_ROOT}/${SYMBOLS}?id=${userId}`)
export const API_ENDPOINT_KLINES = (selectedAuthSymbol, selectedTimeFrame,userId) => (`${API_ROOT}/${KLINES}?s=${selectedAuthSymbol}&tf=${selectedTimeFrame}&userId=${userId}`)
export const API_ENDPOINT_KLINES_MT = (selectedAuthSymbol, selectedTimeFrame,userId) => (`${API_ROOT}/${KLINES_MT}?s=${selectedAuthSymbol}&tf=${selectedTimeFrame}&userId=${userId}`)
export const API_ENDPOINT_LEVERAGE = (userId, symbol) => (`${API_ROOT}/${LEVERAGE}?id=${userId}&s=${symbol}`)
export const API_ENDPOINT_SYMBOL_INFO = (userId, symbol) => (`${API_ROOT}/${SYMBOL_INFO}?id=${userId}&s=${symbol}`)
export const API_ENDPOINT_SESSION_DETAIL = (userId, symbol) => (`${API_ROOT}/${SESSION_DETAIL}?id=${userId}&s=${symbol}`)
export const API_ENDPOINT_POSITION_DETAIL = (userId, positionId) => (`${API_ROOT}/${POSITION_DETAIL}?user_id=${userId}&p_id=${positionId}`)
export const API_ENDPOINT_UPDATE_POSITION_DETAIL = `${API_ROOT}/${UPDATE_POSITION_DETAILS}`

export const API_ENDPOINT_INSERT_WATCHLIST = (p_user_id,p_symbol_name) => (`${API_ROOT}/${INSERT_WATCHLIST}?id=${p_user_id}&sname=${p_symbol_name}`)
export const API_ENDPOINT_DELETE_WATCHLIST = (p_user_id, p_symbol_name) => (`${API_ROOT}/${DELETE_WATCHLIST}?id=${p_user_id}&sname=${p_symbol_name}`);

export const API_ENDPOINT_USER_WATCHLIST = (p_user_id) => (`${API_ROOT}/${USER_WATCHLIST}?id=${p_user_id}`)

export const API_ENDPOINT_SESSION = (userId, symbol) => (`${API_ROOT}/${SESSION}?id=${userId}&s=${symbol}`)
export const API_ENDPOINT_OPENED_ORDERS = (userId) => (`${API_ROOT}/${OPENED_ORDERS}?id=${userId}`)
export const API_ENDPOINT_CLOSED_ORDERS = (userId) => (`${API_ROOT}/${CLOSED_ORDERS}?id=${userId}`)

export const API_ENDPOINT_CLOSE_ALL_POSITIONS = (userId) => {    
    return `${API_ROOT}/${CLOSE_ALL_POSITIONS}?id=${userId}`;
  };
export const API_ENDPOINT_CLOSE_ALL_ORDERS = (userId) => {    
    return `${API_ROOT}/${CLOSE_ALL_ORDERS}?id=${userId}`;
  };
  