import React, { createContext, useContext, useEffect, useState } from 'react';
import APIMiddleware from '../data/api/Api-Middleware';
import { API_ENDPOINT_LEVERAGE, API_ENDPOINT_SYMBOLS, API_ENDPOINT_SESSION, API_ENDPOINT_SYMBOL_INFO, API_ENDPOINT_SESSION_DETAIL } from '../data/Endpoints-API';

import { useAuthContext } from './Auth-Context';

export const SymbolContext = createContext();

export const useSymbolContext = () => {
  return useContext(SymbolContext);
};

export const SymbolProvider = ({ children }) => {

  const { user, updateUserAuthorization, selectedAuthSymbol, setAuthSelectedSymbol, setAuthSelectedCategory, defaultSelectedSymbol, setDefaultSelectedSymbol, allValidSymbols } = useAuthContext();
  // Make Ripple Effect Function
  const mkRipple = (e, getColor) => {
    if(e.target.className != 'ripple_once'){
    const size = e.target.getClientRects()[0].width;
    const ripple = document.createElement('div');
    let color;
    if (getColor == undefined) {
    color = getComputedStyle(e.target).color;
    } else {
    color = getColor;
    }
    e.target.style.position = 'relative';
    e.target.style.overflow = 'hidden';
    ripple.className = 'ripple_once';
    ripple.style.transition = '.6s ease';
    ripple.style.backgroundColor = color;
    ripple.style.position = 'absolute';
    ripple.style.borderRadius = '100%';
    ripple.style.top = `${e.nativeEvent.offsetY - (size / 2)}px`;
    ripple.style.left = `${e.nativeEvent.offsetX - (size / 2)}px`;
    ripple.style.width = `${size}px`;
    ripple.style.height = `${size}px`;
    ripple.style.transform = 'scale(0)';
    ripple.style.opacity = 0.5;
    e.target.append(ripple);
    setTimeout(() => {
    ripple.style.transform = 'scale(2)';
    ripple.style.opacity = 0;
    setTimeout(() => {
    ripple.remove();
    }, 1000);
    }, 50);
    }};

  const [loadingSymbolContext, setLoadingSymbolContext] = useState(true);
  const [bidPrice, setBidPrice] = useState(null);
  const [askPrice, setAskPrice] = useState(null);

  //store list of all symbols
  const [symbolData, setSymbolData] = useState({});
  const [symbolNames, setSymbolNames] = useState({});
  const [symbolWithCategories, setSymbolWithCategories] = useState({});
  const [symbolCategories, setSymbolCategories] = useState({});
  const [selectedCategoryId, setSelectedCategoryId] = useState(1);
  const [selectedSymbolSession, setSelectedSymbolSession] = useState(1);
  const [symbolInfo, setSymbolInfo] = useState({});
  const [marketHours, setMarketHours] = useState({});
  const [leverage, setLeverage] = useState({});
  const [symbolDetails, setsymbolDetails] = useState({});
  const [selectedSymbolOption, setSelectedSymbolOption] = useState({ value: selectedAuthSymbol, label: selectedAuthSymbol });

  const [slPipsTotal, setSlPipsTotal] = useState(0.0)
  const [tpPipsTotal, setTpPipsTotal] = useState(0.0)
    
  const allSymbolOptions = Object.entries(symbolData).map(([key, value]) => ({
    value: key,
    label: key,
    bid: value.bid,
    ask: value.ask,
    category: value.symbol_category,
    is_session_active: value.is_session_active
  }));

  const handleSymbolOptionChange = (selectedAuthSymbol) => {
    setSelectedSymbolOption(selectedAuthSymbol);
    setSelectedCategoryId(selectedAuthSymbol.category);
    setSelectedSymbolSession(selectedAuthSymbol.is_session_active);
    setAuthSelectedCategory(selectedAuthSymbol.category);
    setAuthSelectedSymbol(selectedAuthSymbol.value);
    const { value, bid, ask, category, is_session_active } = selectedAuthSymbol;
    updateSymbolData(value, bid, ask, category);
    // updateSymbolDetailsData(value) 
  };

  useEffect(() => {
    if (user && user.userId != undefined && user.userId > 0) {
        loadSymbolName();
      }
  }, [allValidSymbols]);

  // const loadSymbolName = async () => {
  //   // get symbols by api
  //   // const response = await APIMiddleware.get(API_ENDPOINT_SYMBOLS(user.userId));
  //   // if (response.data) {
  //     // Extracting symbols names
  //     const symbolNames = allValidSymbols[0].map(symbol => symbol.name);
  //     if(localStorage.symbol == null){
  //     setAuthSelectedSymbol(symbolNames[0]);
  //       allValidSymbols[0].map((symbol)=>{
  //         if(symbol.name === symbolNames[0]){
  //           setSelectedCategoryId(symbol.symbol_category);
  //           setAuthSelectedCategory(symbol.symbol_category);
  //           setSelectedSymbolSession(symbol.is_session_active);
  //         }
  //     })
  //       // setSelectedCategoryId(symbolNames[0][0].symbol_category);
  //       // setAuthSelectedCategory(symbolNames[0][0].symbol_category);
  //       // setSelectedSymbolSession(symbolNames[0][0].is_session_active);

  //     }else{
  //       setAuthSelectedSymbol(localStorage.symbol);
  //       allValidSymbols[0].map((symbol)=>{
  //           if(symbol.name === localStorage.symbol){
  //             setSelectedCategoryId(symbol.symbol_category);
  //             setAuthSelectedCategory(symbol.symbol_category);
  //             setSelectedSymbolSession(symbol.is_session_active);
  //           }
  //       })
  //     }
  //     setSymbolNames(symbolNames);

  //     setSymbolWithCategories(allValidSymbols[0]);
  //     setSymbolCategories(allValidSymbols[1]);
  //     const symbolDetailsData = {};

  //     //setting initial values to all symbols
  //     allValidSymbols[0].forEach((symbol) => {
  //         symbolDetailsData[symbol.name] = { pip_position: symbol.pip_position, digit: symbol.digit, symbol_category: symbol.symbol_category, is_session_active: symbol.is_session_active}; 
  //     });

  //     setsymbolDetails(symbolDetailsData);

  //     setLoadingSymbolContext(false);
  //   // }
  // }
  

  const loadSymbolName = async () => {
    // get symbols by api
    // const response = await APIMiddleware.get(API_ENDPOINT_SYMBOLS(user.userId));
    // if (response.data) {
      // Extracting symbols names
      const symbolNames = allValidSymbols[0].map(symbol => symbol);
      if(localStorage.symbol == null){
        console.log(symbolNames[0].symbol_category,symbolNames[0].name)
        setAuthSelectedSymbol(symbolNames[0].name);
        setSelectedCategoryId(symbolNames[0].symbol_category);
        setAuthSelectedCategory(symbolNames[0].symbol_category);
        setSelectedSymbolSession(symbolNames[0].is_session_active);

      }else{
      
        setAuthSelectedSymbol(localStorage.symbol);
        allValidSymbols[0].map((symbol)=>{
            if(symbol.name === localStorage.symbol){
              setSelectedCategoryId(symbol.symbol_category);
              setAuthSelectedCategory(symbol.symbol_category);
              setSelectedSymbolSession(symbol.is_session_active);
          
            }
        })
      }
      setSymbolNames(symbolNames.map(symbol => symbol.name));

      setSymbolWithCategories(allValidSymbols[0]);
      setSymbolCategories(allValidSymbols[1]);
      const symbolDetailsData = {};

      //setting initial values to all symbols
      allValidSymbols[0].forEach((symbol) => {
          symbolDetailsData[symbol.name] = { pip_position: symbol.pip_position, digit: symbol.digit, symbol_category: symbol.symbol_category, is_session_active: symbol.is_session_active}; 
      });

      setsymbolDetails(symbolDetailsData);

      setLoadingSymbolContext(false);
    // }
  }

  //load all symbols data
  const loadAllSymbolsData = (data) => {
    setSymbolData(data);
  }

  //update symbols default values
  const updateSymbolData = (symbol, bid, ask, category_id) => {

    setAuthSelectedSymbol(symbol);
    setBidPrice(bid);
    setAskPrice(ask);
    // updateSymbolInfo(symbol);
    // updateMarketHours(symbol);
    // updateLeverage(symbol);
    setSelectedSymbolOption({ value: symbol, label: symbol });
  };

  //update symbols lev/ mkt hours etc
  const updateSymbolDetailsData = (symbol) => {
    updateLeverage(symbol);
    updateSymbolInfo(symbol);
    updateMarketHours(symbol);
  };

  const updateSymbolName = (symbol) => {

    setAuthSelectedSymbol(symbol);
  };

  const updateSymbolInfo = async (symbol) => {
    try {
      if (user && user.userId != undefined && user.userId > 0) {
            // get the selected symbol's info using param and return that data in setSymbolInfo as param to show on frontend

        const parts = symbol.split('USDT');
        const quoteAsset = 'USDT';
        const baseAsset = parts[0];
        const response = await APIMiddleware.get(API_ENDPOINT_SYMBOL_INFO(user.userId, symbol));
        const responseData = response.data[0];
        const symbolInfo =  {
            base_asset: responseData.base_asset ? responseData.base_asset : baseAsset,
            quote_asset: responseData.quote_asset ? responseData.quote_asset : quoteAsset,
            pip_position: responseData.pip_position,
            lot_size: responseData.lot_size,
            swap_long: responseData.swap_long,
            swap_short: responseData.swap_short,
            three_day_swaps: responseData.three_days_swap,
            digit: responseData.digit,
          };
          setSymbolInfo(symbolInfo);
      }
    } catch (error) {
      // Handle API request error
      console.error(`API request error: ${API_ENDPOINT_SYMBOL_INFO}`, error);
    }
  };

  const updateMarketHours = async (symbol) => {
    try {
        if (user && user.userId !== undefined && user.userId > 0) {
            const response = await APIMiddleware.get(API_ENDPOINT_SESSION_DETAIL(user.userId, symbol));
            const marketHours = response.data.map((rowData) => {
                const isActive = rowData.is_active === 1; // Convert 1 to true, 0 to false
                return {
                    start_day: rowData.start_day,
                    start_time: rowData.start_time,
                    end_day: rowData.end_day,
                    end_time: rowData.end_time,
                    is_active: isActive,
                };
            });

            setMarketHours(marketHours);
        }
    } catch (error) {
        // Handle API request error   
        console.error(`API request error: ${API_ENDPOINT_SESSION_DETAIL}`, error);
    }
};

  const updateLeverage = async (symbol) => {
    try {
      if (user && user.userId != undefined && user.userId > 0) {
        const response = await APIMiddleware.get(API_ENDPOINT_LEVERAGE(user.userId, symbol));
        const responseData = response.data[0];
        const leverage = [
          {
            exposure_level: responseData.exposure_level.toString(),
            available_leverage: responseData.available_leverage.toString(),
            is_hidden: responseData.is_hidden
          }
        ];
        setLeverage(leverage);
      }
    } catch (error) {
      // Handle API request error
      console.error(`API request error: ${API_ENDPOINT_LEVERAGE}`, error);
    }
  };


  return (
    <SymbolContext.Provider value={{
      loadingSymbolContext, symbolData, bidPrice, askPrice, symbolNames, symbolInfo, marketHours, leverage, allSymbolOptions, selectedSymbolOption, 
      symbolDetails,slPipsTotal, setSlPipsTotal,tpPipsTotal, setTpPipsTotal,
      loadAllSymbolsData, updateSymbolData, updateSymbolName, handleSymbolOptionChange, mkRipple, updateSymbolDetailsData,setLoadingSymbolContext, symbolWithCategories, symbolCategories, selectedCategoryId, setSelectedCategoryId, selectedSymbolSession, setSelectedSymbolSession
    }}>
      {children}
    </SymbolContext.Provider>
  );
};