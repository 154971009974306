import React, { useEffect, useState } from "react";
import { useSymbolContext } from "../../../contexts/Symbol-Context.js";
import { useOrderContext } from "../../../contexts/Order-Context.js";
import { useMetricsContext } from "../../../contexts/Metrics-Context.js";
import { useAuthContext } from "../../../contexts/Auth-Context.js";
import { formatPositionToPipSize } from "../../../utils/format.js";
// import "./Profit-Loss.css";
const SLTP = ({
  handleProfitChange,
  handleLossChange,
  direction,
  quantity,
  entryPrice,
}) => {
  const {
    mkRipple,
    symbolInfo,
    setSlPipsTotal,
    setTpPipsTotal,
  } = useSymbolContext();

  const { exchangeRate, quoteExchangeRate } = useOrderContext();
  const { metrics } = useMetricsContext();
  const { user,selectedAuthSymbol } = useAuthContext();

  const [isSLSelected, setIsSLSelected] = useState(false);
  const [isTPSelected, setIsTPSelected] = useState(false);
  const [slPips, setSlPips] = useState(0.0);
  const [tpPips, setTpPips] = useState(0.0);
  const [pipSize, setPipSize] = useState(0.0);
  const [slPrice, setSlPrice] = useState(0.0);
  const [slBalance, setSlBalance] = useState(0.0);
  const [slProfit, setSlProfit] = useState(0.0);
  const [tpPrice, setTpPrice] = useState(0.0);
  const [tpBalance, setTpBalance] = useState(0.0);
  const [tpProfit, setTpProfit] = useState(0.0);
  const [slSelectedState, setSlSelectedState] = useState("");

  const [tpSelectedState, setTpSelectedState] = useState("");

  useEffect(() => {
    setPipSize(formatPositionToPipSize(symbolInfo.pip_position));
  }, [symbolInfo]);

  useEffect(() => {
    if (slSelectedState === "pips") {
      slPips_fn(slPips);
    } else if (slSelectedState === "price") {
      slPrice_fn(slPrice);
    } else if (slSelectedState === "balance") {
      slBalance_fn(slBalance);
    } else if (slSelectedState === "profit") {
      slProfit_fn(slProfit);
    }

    if (tpSelectedState === "pips") {
      tpPips_fn(tpPips);
    } else if (tpSelectedState === "price") {
      tpPrice_fn(tpPrice);
    } else if (tpSelectedState === "balance") {
      tpBalance_fn(tpBalance);
    } else if (tpSelectedState === "profit") {
      tpProfit_fn(tpProfit);
    }
  }, [direction, quantity, entryPrice]);

  useEffect(() => {
    setSlPips(0.0);
    setSlPipsTotal(0.0);
    setSlPrice(0.0);
    setSlProfit(0.0);
    setSlBalance(0.0);
    setTpPips(0.0);
    setTpPrice(0.0);
    setTpProfit(0.0);
    setTpBalance(0.0);
    handleLossChange(0.0);
    handleProfitChange(0.0);
  }, [selectedAuthSymbol]);

  useEffect(() => {
    setSlPips(0.0);
    setSlPipsTotal(0.0);
    setSlPrice(0.0);
    setSlProfit(0.0);
    setSlBalance(0.0);
    setTpPips(0.0);
    setTpPrice(0.0);
    setTpProfit(0.0);
    setTpBalance(0.0);
    handleLossChange(0.0);
    handleProfitChange(0.0);
    setSlSelectedState("");
    setTpSelectedState("");
  }, [
    quantity === null ||
      quantity <= 0 ||
      quantity === undefined ||
      isNaN(quantity),
  ]);

  const sltpToggle = (e) => {
    if (e.target.id === "stop_loss") {
      setIsSLSelected(e.target.checked);

      setSlPips(0.0);
      setSlPrice(0.0);
      setSlPipsTotal(0.0);
      setSlProfit(0.0);
      setSlBalance(0.0);
      handleLossChange(0.0);

      setSlSelectedState("");
    }

    if (e.target.id === "take_profit") {
      setIsTPSelected(e.target.checked);
      setTpPips(0.0);
      setTpPipsTotal(0.0);
      setTpPrice(0.0);
      setTpProfit(0.0);
      setTpBalance(0.0);
      handleProfitChange(0.0);
      setTpSelectedState("");
    }
  };

  const slPips_fn = (e) => {
    setSlSelectedState("pips");
    setSlPips(e);
    setSlPipsTotal(e);
    if (direction == "Buy") {
      const pip_movement = pipSize * e;

      const price = entryPrice - pip_movement;
      const loss_profit_in_quote = quantity * (entryPrice - price);
      const loss_profit_in_account_currecny =
        loss_profit_in_quote * exchangeRate;

      const balance = (loss_profit_in_account_currecny / metrics.balance) * 100;

      setSlPrice(price);

      setSlBalance(balance);
      setSlProfit(loss_profit_in_account_currecny);

      handleLossChange(price);
    }

    ///////STOP LOSS DIRECTION SELL
    if (direction == "Sell") {
      const pip_movement = pipSize * e;

      const price = entryPrice + pip_movement;

      const loss_profit_in_quote = quantity * (price - entryPrice);
      const loss_profit_in_account_currecny =
        loss_profit_in_quote * exchangeRate;
      const balance = (loss_profit_in_account_currecny / metrics.balance) * 100;

      setSlPrice(price);

      setSlBalance(balance);
      setSlProfit(loss_profit_in_account_currecny);

      handleLossChange(price);
    }
  };
  const slPrice_fn = (e) => {
    setSlSelectedState("price");
    setSlPrice(e);

    if (direction == "Buy") {
      const pip_movement = entryPrice - e;
      const pips = pip_movement / pipSize;
      const loss_profit_in_quote = quantity * (entryPrice - e);

      const loss_profit_in_account_currecny =
        loss_profit_in_quote * exchangeRate;
      const balance = (loss_profit_in_account_currecny / metrics.balance) * 100;

      setSlPips(pips);
      setSlPipsTotal(pips);
      setSlBalance(balance);
      setSlProfit(loss_profit_in_account_currecny);
      handleLossChange(parseFloat(e));
    }

    ////////////STOP LOSS DIRECTION SELL
    if (direction == "Sell") {
      const pip_movement = entryPrice - e;

      const pips = pip_movement / pipSize;

      const loss_profit_in_quote = quantity * (e - entryPrice);

      const loss_profit_in_account_currecny =
        loss_profit_in_quote * exchangeRate;
      const balance = (loss_profit_in_account_currecny / metrics.balance) * 100;

      setSlPips(pips);
      setSlPipsTotal(pips);
      setSlBalance(balance);
      setSlProfit(loss_profit_in_account_currecny);
      handleLossChange(parseFloat(e));
    }
  };
  const slBalance_fn = (e) => {
    setSlSelectedState("balance");
    setSlBalance(e);

    if (direction == "Buy") {
      const loss_profit_in_account_currecny = (metrics.balance / 100) * e;
      const price =
        entryPrice -
        (loss_profit_in_account_currecny / quantity) * quoteExchangeRate;
      const pip_movement = entryPrice - price;
      const pips = pip_movement / pipSize;

      setSlPips(pips);
      setSlPipsTotal(pips);
      setSlPrice(price);

      setSlProfit(loss_profit_in_account_currecny);
      handleLossChange(price);
    }

    // ////////STOP LOSS DIRECTION SELL
    if (direction == "Sell") {
      const loss_profit_in_account_currecny = (metrics.balance / 100) * e;
      const price =
        entryPrice +
        (loss_profit_in_account_currecny / quantity) * quoteExchangeRate;
      const pip_movement = price - entryPrice;
      const pips = pip_movement / pipSize;

      setSlPips(pips);
      setSlPipsTotal(pips);
      setSlPrice(price);

      setSlProfit(loss_profit_in_account_currecny);
      handleLossChange(price);
    }
  };
  const slProfit_fn = (e) => {
    setSlSelectedState("profit");
    setSlProfit(e);

    if (direction == "Buy") {
      const balance = (e / metrics.balance) * 100;

      const price = entryPrice - (e / quantity) * quoteExchangeRate;

      const pip_movement = entryPrice - price;

      const pips = pip_movement / pipSize;

      setSlPips(pips);
      setSlPipsTotal(pips);
      setSlBalance(balance);

      setSlPrice(price);

      handleLossChange(price);
    }

    if (direction == "Sell") {
      const balance = (e / metrics.balance) * 100;

      const price = entryPrice + (e / quantity) * quoteExchangeRate;

      const pip_movement = price - entryPrice;

      const pips = pip_movement / pipSize;

      setSlPips(pips);
      setSlPipsTotal(pips);
      setSlBalance(balance);
      setSlPrice(price);

      handleLossChange(price);
    }
  };
  const tpPips_fn = (e) => {
    setTpSelectedState("pips");
    setTpPips(e);
    setTpPipsTotal(e);
    if (direction == "Buy") {
      const pip_movement = e * pipSize;
      const price = entryPrice + pip_movement;
      const loss_profit_in_quote = quantity * (price - entryPrice);

      const loss_profit_in_account_currecny =
        loss_profit_in_quote * exchangeRate;

      const balance = (loss_profit_in_account_currecny / metrics.balance) * 100;
      setTpPrice(price);
      setTpBalance(balance);
      setTpProfit(loss_profit_in_account_currecny);

      handleProfitChange(price);
    }

    ///////TAKE PROFIT DIRECTION SELL
    if (direction == "Sell") {
      const pip_movement = e * pipSize;
      const price = entryPrice - pip_movement;
      const loss_profit_in_quote = quantity * (entryPrice - price);
      const loss_profit_in_account_currecny =
        loss_profit_in_quote * exchangeRate;
      const balance = (loss_profit_in_account_currecny / metrics.balance) * 100;

      setTpPrice(price);
      setTpBalance(balance);
      setTpProfit(loss_profit_in_account_currecny);

      handleProfitChange(price);
    }
  };
  const tpPrice_fn = (e) => {
    setTpSelectedState("price");
    setTpPrice(e);

    if (direction == "Buy") {
      const pip_movement = entryPrice - e;
      const pips = pip_movement / pipSize;
      const loss_profit_in_quote = quantity * (e - entryPrice);
      const loss_profit_in_account_currecny =
        loss_profit_in_quote * quoteExchangeRate;
      const balance = (loss_profit_in_account_currecny / metrics.balance) * 100;

      setTpPips(pips);
      setTpPipsTotal(pips);
      setTpBalance(balance);
      setTpProfit(loss_profit_in_account_currecny);
      handleProfitChange(parseFloat(e));
    }

    ////////////Take PROFIT DIRECTION SELL
    if (direction == "Sell") {
      const pip_movement = entryPrice - e;
      const pips = pip_movement / pipSize;
      const loss_profit_in_quote = quantity * (e - entryPrice);
      const loss_profit_in_account_currecny =
        loss_profit_in_quote * quoteExchangeRate;
      const balance = (loss_profit_in_account_currecny / metrics.balance) * 100;

      setTpPips(pips);
      setTpPipsTotal(pips);
      setTpBalance(balance);
      setTpProfit(loss_profit_in_account_currecny);
      handleProfitChange(parseFloat(e));
    }
  };
  const tpBalance_fn = (e) => {
    setTpSelectedState("balance");
    setTpBalance(e);

    if (direction == "Buy") {
      const loss_profit_in_account_currecny = (metrics.balance / 100) * e;

      const price =
        entryPrice +
        (loss_profit_in_account_currecny / quantity) * quoteExchangeRate;

      const pip_movement = price - entryPrice;

      const pips = pip_movement / pipSize;

      setTpPips(pips);
      setTpPipsTotal(pips);
      setTpPrice(price);
      setTpProfit(loss_profit_in_account_currecny);

      handleProfitChange(price);
    }

    ////////Take Profit DIRECTION SELL
    if (direction == "Sell") {
      const loss_profit_in_account_currecny = (metrics.balance / 100) * e;

      const price =
        entryPrice -
        (loss_profit_in_account_currecny / quantity) * quoteExchangeRate;

      const pip_movement = price - entryPrice;

      const pips = pip_movement / pipSize;

      setTpPips(pips);
      setTpPipsTotal(pips);
      setTpPrice(price);
      setTpProfit(loss_profit_in_account_currecny);

      handleProfitChange(price);
    }
  };
  const tpProfit_fn = (e) => {
    setTpSelectedState("profit");
    setTpProfit(e);

    if (direction == "Buy") {
      const balance = (e / metrics.balance) * 100;

      const price = entryPrice + (e / quantity) * quoteExchangeRate;

      const pip_movement = entryPrice - price;

      const pips = pip_movement / pipSize;

      setTpPips(pips);
      setTpPipsTotal(pips);
      setTpBalance(balance);
      setTpPrice(price);

      handleProfitChange(price);
    }

    if (direction == "Sell") {
      const balance = (e / metrics.balance) * 100;

      const price = entryPrice - (e / quantity) * quoteExchangeRate;

      const pip_movement = entryPrice - price;

      const pips = pip_movement / pipSize;

      setTpPips(pips);
      setTpPipsTotal(pips);
      setTpBalance(balance);
      setTpPrice(price);

      handleProfitChange(price);
    }
  };
  const isDisabledSLInput = () => {
    if (quantity > 0 && isSLSelected && entryPrice > 0 ) {
      return false;
    } else {
      return true;
    }
  };
  const isDisabledTPInput = () => {
    if (quantity > 0 && isTPSelected && entryPrice > 0) {
      return false;
    } else {
      return true;
    }
  };
  return (
    <>
      <div
        className="sltp-container"
        style={{ display: "flex", width: "100%" }}
      >
        <div className="left">
          <div className="stop-loss sltp-checkbox">
            <input
              hidden
              type="checkbox"
              id="stop_loss"
              checked={isSLSelected}
              onChange={sltpToggle}
            />
            <label
              htmlFor="stop_loss"
              className="my-chk-xbx"
              role={isSLSelected ? "checked" : "unchecked"}
            >
              <div
                className="chkx-xbx"
                onClick={(j) => {
                  mkRipple(j);
                }}
              ></div>
              <span className="col-white">Stop Loss</span>
            </label>
          </div>
          {(isSLSelected || isTPSelected) && (
            <div className="input-ov">
              <input
                type="number"
                className={`sl_pips ${
                  isDisabledSLInput() ? "disabled-fields" : ""
                }`}
                value={slPips}
                disabled={isDisabledSLInput()}
                onChange={(e) => slPips_fn(parseFloat(e.target.value))}
              />
              <input
                value={slPrice}
                onChange={(e) => slPrice_fn(parseFloat(e.target.value))}
                type="number"
                disabled={isDisabledSLInput()}
                className={`sl_price ${
                  isDisabledSLInput() ? "disabled-fields" : ""
                }`}
              />
              <input
                value={slBalance}
                onChange={(e) => slBalance_fn(parseFloat(e.target.value))}
                type="number"
                disabled={isDisabledSLInput()}
                className={`sl_balance ${
                  isDisabledSLInput() ? "disabled-fields" : ""
                }`}
              />
              <input
                value={slProfit}
                onChange={(e) => slProfit_fn(parseFloat(e.target.value))}
                type="number"
                disabled={isDisabledSLInput()}
                className={`sl_profit ${
                  isDisabledSLInput() ? "disabled-fields" : ""
                }`}
              />
            </div>
          )}
        </div>
        {(isSLSelected || isTPSelected) && (
          <div className="center input-ov labels-pips">
            <p></p>
            <p className="col-white">Pips</p>
            <p className="col-white">Price</p>
            <p>
              <pre className="col-white">% Balance </pre>
            </p>
            <p>
              <pre className="col-white">
                Profit ~ {user?.userCurrencyName || "USD"}
              </pre>
            </p>
          </div>
        )}
        <div className="right">
          <div className="take-profit sltp-checkbox">
            <input
              hidden
              type="checkbox"
              name="take_profit"
              id="take_profit"
              checked={isTPSelected}
              onChange={sltpToggle}
            />
            <label
              htmlFor="take_profit"
              className="my-chk-xbx"
              role={isTPSelected ? "checked" : "unchecked"}
            >
              <div
                className="chkx-xbx"
                onClick={(j) => {
                  mkRipple(j);
                }}
              ></div>
              <span className="col-white">Take Profit</span>
            </label>
          </div>
          {(isSLSelected || isTPSelected) && (
            <div className="input-ov">
              <input
                value={tpPips}
                type="number"
                disabled={isDisabledTPInput()}
                className={`tp_pips ${
                  isDisabledTPInput() ? "disabled-fields" : ""
                }`}
                onChange={(e) => tpPips_fn(parseFloat(e.target.value))}
              />

              <input
                value={tpPrice}
                type="number"
                className={`tp_price ${
                  isDisabledTPInput() ? "disabled-fields" : ""
                }`}
                disabled={isDisabledTPInput()}
                onChange={(e) => tpPrice_fn(parseFloat(e.target.value))}
              />
              <input
                value={tpBalance}
                type="number"
                className={`tp_balance ${
                  isDisabledTPInput() ? "disabled-fields" : ""
                }`}
                disabled={isDisabledTPInput()}
                onChange={(e) => tpBalance_fn(parseFloat(e.target.value))}
              />
              <input
                value={tpProfit}
                type="number"
                className={`tp_profit ${
                  isDisabledTPInput() ? "disabled-fields" : ""
                }`}
                disabled={isDisabledTPInput()}
                onChange={(e) => tpProfit_fn(parseFloat(e.target.value))}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SLTP;
