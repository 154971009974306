import React, { useState, useEffect } from "react";
import Spinner from "../../utils/spinner/Spinner";
import { toast } from "react-toastify";
import { useSymbolContext } from "../../../contexts/Symbol-Context.js";
import {
  API_ENDPOINT_POSITION_DETAIL,
  API_ENDPOINT_UPDATE_POSITION_DETAIL,
} from "../../../data/Endpoints-API.js";
import APIMiddleware from "../../../data/api/Api-Middleware.js";
import { useAuthContext } from "../../../contexts/Auth-Context.js";
import { FaTimes } from "react-icons/fa";
import { formatPrice } from "../../../utils/format.js";
import SLTP from "../market_order/SLTP.jsx";

interface EditSltpProps {
  onCancel: () => void;
  editPositionId: any;
  currentPrice: any;
}

const EditSltp: React.FC<EditSltpProps> = ({
  onCancel,
  editPositionId,
  currentPrice,
}) => {
  //CONTEXT
  const { loadingSymbolContext, slPipsTotal, tpPipsTotal } = useSymbolContext();
  const { user,selectedAuthSymbol } = useAuthContext();

  // #region All states defined here States

  const [direction, setDirection] = useState("Buy");
  const [tradeQuantity, setTradeQuantity] = useState(0.0);
  const [tradeEntryPrice, setTradeEntryPrice] = useState(0.0);
  const [takeProfit, setTakeProfit] = useState(0.0);
  const [stopLoss, setStopLoss] = useState(0.0);
  const [selectedPosition, setSelectedPosition] = useState({
    SL: null,
    TP: null,
    position_id: "",
    direction: "",
    entry_price: "",
    symbol: "",
  });
  // SL: null, TP: null, position_id: '', direction: '', entry_price: ''

  // #endregion

  // #region All UseEffect defined here States

  useEffect(() => {
    setTradeEntryPrice(parseFloat(currentPrice.current_price));
  }, [currentPrice.current_price]);

  useEffect(() => {
    if (editPositionId) {
      updateSelectedPosition(editPositionId);
    }
  }, [editPositionId]);

  // #region All functions defined here States

  const handleProfitChange = (data) => {
    setTakeProfit(data);
  };
  const handleLossChange = (data) => {
    setStopLoss(data);
  };

  const updateSelectedPosition = async (p_id) => {
    try {
      if (user && user.userId !== undefined && user.userId > 0) {
        const response = await APIMiddleware.get(
          API_ENDPOINT_POSITION_DETAIL(user.userId, p_id)
        );
        if (response.data) {
          const positionDetails = response.data[0];
          setSelectedPosition(positionDetails);
          setDirection(positionDetails.direction);
          setTradeQuantity(positionDetails.quantity);
        }
      }
    } catch (error) {
      // Handle API request error
      console.error(
        `API request error: ${API_ENDPOINT_POSITION_DETAIL}`,
        error
      );
    }
  };
  const onSubmit = async () => {
    try {
      if (user && user.userId !== undefined && user.userId > 0) {
        const response = await APIMiddleware.post(
          API_ENDPOINT_UPDATE_POSITION_DETAIL,
          {
            userId: user.userId,
            positionId: selectedPosition.position_id,
            SL: stopLoss,
            TP: takeProfit,
            symbol: selectedAuthSymbol,
            quantity:tradeQuantity,
            entry_price:tradeEntryPrice,
            direction: direction,
            stop_loss_pips: slPipsTotal,
            take_profit_pips:tpPipsTotal,
          }
        );

        if (response.data) {
          setTakeProfit(0.0);
          setStopLoss(0.0);
          toast.success(response.data[0].message, { position: "top-right" });

          onCancel();
        }
      }
    } catch (error) {
      // Handle API request error
      console.error(
        `API request error: ${API_ENDPOINT_POSITION_DETAIL}`,
        error
      );
    }
  };
  //show spinner while loading data
  if (loadingSymbolContext) {
    return <Spinner />;
  }

  return (
    <div className="dialog-modal">
      <div className="card">
        <div className="model-header">
          <h2>Modify Position: {selectedPosition?.position_id}</h2>
          <button
            onClick={() => {
              onCancel();
            }}
            className="close-icon"
          >
            <FaTimes />
          </button>
        </div>
        <div className="model-details">
          <p>Symbol:</p> <h2>{selectedPosition?.symbol}</h2>
          <p>Current Price:</p> <h2> {formatPrice(tradeEntryPrice)}</h2>
        </div>

        <div className="model-sltp-container">
          <SLTP
            handleProfitChange={handleProfitChange}
            handleLossChange={handleLossChange}
            direction={direction}
            quantity={tradeQuantity}
            entryPrice={tradeEntryPrice}
          />
        </div>

        <div className="row-scss">
          <button
            onClick={() => {
              onCancel();
            }}
          >
            Cancel
          </button>
          <button
            onClick={() => {
              onSubmit();
            }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditSltp;
