import React, { useState, useEffect } from "react";
import OrderBook from "../order_book/Order-Book.jsx";
import { useOrderContext } from "../../../contexts/Order-Context.js";
import Spinner from "../../utils/spinner/Spinner";
import { useSymbolContext } from "../../../contexts/Symbol-Context.js";
import "./Order-Panel.scss";
import { BsArrowUpSquare, BsArrowDownSquare } from "react-icons/bs";
import { GoDotFill } from "react-icons/go";
import Select from "react-select";
import MarketOrder from "../market_order/Market-Order.tsx";
import PendingOrder from "../pending_order/Pending-Order.tsx";

const OrderPanel: React.FC = () => {
  const { font, setFont } = useOrderContext();
  let prePositionMarkerState = 'show';
  if(localStorage.positionMarker !== undefined){
    prePositionMarkerState = localStorage.positionMarker;
  }else{
    localStorage.setItem('positionMarker',prePositionMarkerState);
  }
  const [positionMarker,setPositionMarker] = useState(prePositionMarkerState);
  const setPositionMarkerValue =(e)=>{
    setPositionMarker(e);
    localStorage.setItem('positionMarker',e);
  }
  const fontAdjuster = (e) => {
    setFont(e.target.value);
    localStorage.setItem("storedFont", e.target.value);
  };
  //CONTEXT
  const {
    loadingSymbolContext,
    
    symbolInfo,
    marketHours,
    symbolData,
    updateSymbolData,
    allSymbolOptions,
    selectedSymbolOption,
    handleSymbolOptionChange,
    leverage,
    mkRipple,
  } = useSymbolContext();

  let toggleTooltipLocal;
  if(localStorage.chartTooltips !== undefined){
    toggleTooltipLocal = localStorage.chartTooltips;
  }else{
    localStorage.setItem('chartTooltips','show');
    toggleTooltipLocal = 'show';
  }
  const [toggleTooltip,setToggleTooltip] = useState(toggleTooltipLocal);

  const resetChanges = () => {
    const c: boolean = window.confirm(
      "Are you sure you want to reset all the changes from this browser?"
    );
    if (c) {
      const makeSure = () => {
        for (let i = 0; i < localStorage.length; i++) {
          if (
            localStorage.key(i) !== "userId" &&
            localStorage.key(i) !== "userData" &&
            localStorage.key(i) !== "variantId"
          ) {
            localStorage.removeItem(localStorage.key(i));
            if (localStorage.length !== 1) {
              makeSure();
              window.location.reload();
            }
          }
        }
      };
      makeSure();
    }
  };

  //LOcal States
  const [activeMode, setActiveMode] = useState("order");
  const [selectedTab, setSelectedTab] = useState("market");
  useEffect(() => {
    if (localStorage.getItem("activeMode") != null) {
      setActiveMode(localStorage.getItem("activeMode"));
    }
    if (localStorage.getItem("orderTab") != null) {
      setSelectedTab(localStorage.getItem("orderTab"));
    }
  }, [localStorage.getItem("activeMode"), localStorage.orderTab]);
//  var rightCollapse;
//   if(localStorage.orderPanelToggle ===  undefined){
//     rightCollapse = {
//       orders: true,
//       symbols: false,
//       market_hours: false,
//       leverage: false,
//     }
//   }else{
//     rightCollapse = JSON.parse(localStorage.orderPanelToggle);
//   }

  const [collapseState, setCollapseState] = useState({
    orders: true,
    symbols: false,
    market_hours: false,
    leverage: false,
  });
  // localStorage.setItem('orderPanelToggle',JSON.stringify(collapseState));

  // Determine classes based on activeMode
  const orderPanelClass = activeMode === "order" ? "visible" : "hidden";
  const domClass = activeMode === "dom" ? "visible" : "hidden";
  const settingsClass = activeMode === "settings" ? "visible" : "hidden";

  const handleModeClick = (mode: string) => {
    setActiveMode(mode);
    localStorage.setItem("activeMode", mode);
  };

  const handleTabClick = (tab: string) => {
    setSelectedTab(tab);
    localStorage.setItem("orderTab", tab);
  };

  //const getEntryPrice = () => (entryPrice || (buyActive ? bidPrice : askPrice));
  // const getEntryPrice = () => (buyActive ? bidPrice : askPrice);
  //#endregion

  //show spinner while loading data
  if (loadingSymbolContext) {
    return <Spinner />;
  }

  const handleCollapseClick = (section) => {
    setCollapseState((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  return (
    <>
      <div className="mode-tabs">
        <button
          className={`mode-tab-button ${
            activeMode === "order" ? "mode-tab-active" : ""
          }`}
          onClick={(e) => {
            handleModeClick("order");
            mkRipple(e);
          }}
        >
          Order
        </button>
        <button
          className={`mode-tab-button ${
            activeMode === "dom" ? "mode-tab-active" : ""
          }`}
          onClick={(e) => {
            handleModeClick("dom");
            mkRipple(e);
          }}
        >
          DOM
        </button>
        <button
          className={`mode-tab-button ${
            activeMode === "settings" ? "mode-tab-active" : ""
          }`}
          onClick={(e) => {
            handleModeClick("settings");
            mkRipple(e);
          }}
        >
          Settings
        </button>
      </div>
      <div className={`order-panel ${orderPanelClass}`}>
        <div className="symbol-selector-container">
          <Select
            value={selectedSymbolOption}
            onChange={handleSymbolOptionChange}
            options={allSymbolOptions}
            isSearchable
            placeholder="Select Symbol"
          />
        </div>
        <div
          className="collapse-card-header"
          onClick={() => handleCollapseClick("orders")}
        >
          <label htmlFor="text">New Order</label>
          <div className="arrow-icons">
            <BsArrowUpSquare
              className={`${collapseState["orders"] ? "visible" : "hidden"}`}
            />
            <BsArrowDownSquare
              className={`${!collapseState["orders"] ? "visible" : "hidden"}`}
            />
          </div>
        </div>
        <div
          className={`collapse-card-container`}
          role={`${collapseState["orders"] ? "visible" : "hidden"}`}
        >
          <div className="collapse-card-wrapper">
            <div className="order-tabs">
              <button
                className={`tab-button ${
                  selectedTab === "market" ? "order-tab-active" : ""
                }`}
                onClick={(e) => {
                  handleTabClick("market");
                  mkRipple(e);
                }}
              >
                Market
              </button>
              <button
                className={`tab-button ${
                  selectedTab === "pending-order" ? "order-tab-active" : ""
                }`}
                onClick={(e) => {
                  handleTabClick("pending-order");
                  mkRipple(e);
                }}
              >
                Pending Order
              </button>
            </div>
            <div
              className={`order-section ${
                selectedTab === "market" ? "visible" : "hidden"
              }`}
              id="market"
            >
              <MarketOrder selectedOrderTab={selectedTab} />
            </div>
            <div
              className={`order-section ${
                selectedTab === "pending-order" ? "visible" : "hidden"
              }`}
              id="market"
            >
              <PendingOrder selectedOrderTab={selectedTab}/>
            </div>
          </div>
        </div>

        {/* Symbols Info */}
        <div
          className="collapse-card-header"
          onClick={() => handleCollapseClick("symbols")}
        >
          <label htmlFor="text">Symbol Info</label>
          <div className="arrow-icons">
            <BsArrowUpSquare
              className={`${collapseState["symbols"] ? "visible" : "hidden"}`}
            />
            <BsArrowDownSquare
              className={`${!collapseState["symbols"] ? "visible" : "hidden"}`}
            />
          </div>
        </div>
        <div
          className={`collapse-card-container`}
          role={`${collapseState["symbols"] ? "visible" : "hidden"}`}
        >
          <div className="collapse-card-wrapper">
            <div className="symbol-info-panel">
              <ul className="symbol-info-list">
                {/* <li>
                  <strong>Base Asset</strong>{" "}
                  <span>{symbolInfo?.base_asset}</span>
                </li>
                <li>
                  <strong>Quote Asset</strong>{" "}
                  <span>{symbolInfo?.quote_asset}</span>
                </li> */}
                <li>
                  <strong>Pip Position</strong>{" "}
                  <span>{symbolInfo?.pip_position}</span>
                </li>
                <li>
                  <strong>Lot Size</strong> <span>{symbolInfo?.lot_size}</span>
                </li>
                <li>
                  <strong>Swap (long)</strong>{" "}
                  <span>{symbolInfo?.swap_long} pips</span>
                </li>
                <li>
                  <strong>Swap (short)</strong>{" "}
                  <span>{symbolInfo?.swap_short} pips</span>
                </li>
                <li>
                  <strong>3-day swaps</strong>{" "}
                  <span>{symbolInfo?.three_day_swaps}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Markets sections */}
        <div
          className="collapse-card-header"
          onClick={() => handleCollapseClick("market_hours")}
        >
          <label htmlFor="text">Market Hours</label>
          <div className="arrow-icons">
            <BsArrowUpSquare
              className={`${
                collapseState["market_hours"] ? "visible" : "hidden"
              }`}
            />
            <BsArrowDownSquare
              className={`${
                !collapseState["market_hours"] ? "visible" : "hidden"
              }`}
            />
          </div>
        </div>
        <div
          className={`collapse-card-container`}
          role={`${collapseState["market_hours"] ? "visible" : "hidden"}`}
        >
          <div className="collapse-card-wrapper">
            <div className="market-hours-panel">
              <ul className="market-hours-list">
                {Array.isArray(marketHours) &&
                  marketHours.map((market_hours, index) => {
                    return (
                      <li key={index}>
                        <strong>
                          {market_hours.start_day} {market_hours.start_time} -{" "}
                          {market_hours.end_time}
                        </strong>{" "}
                        <span
                          // className={`${
                          //   market_hours.is_active == true
                          //     ? "visible"
                          //     : "hidden"
                          // }`}
                        >
                          <GoDotFill className={market_hours.is_active == true ? 'svg-green' : 'svg-red'} 
                          // color={}
                           />
                        </span>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>

        {/* leverage sections */}
        <div>
          {leverage && leverage.length > 0 && leverage[0].is_hidden == 0 && (
            <div>
              <div
                className="collapse-card-header"
                onClick={() => handleCollapseClick("leverage")}
              >
                <label htmlFor="text">Leverage</label>
                <div className="arrow-icons">
                  <BsArrowUpSquare
                    className={`${
                      collapseState["leverage"] ? "visible" : "hidden"
                    }`}
                  />
                  <BsArrowDownSquare
                    className={`${
                      !collapseState["leverage"] ? "visible" : "hidden"
                    }`}
                  />
                </div>
              </div>
              <div
                className={`collapse-card-container`}
                role={`${collapseState["leverage"] ? "visible" : "hidden"}`}
              >
                <div className="collapse-card-wrapper">
                  <div className="leverage-panel">
                    <ul className="leverage-list">
                      <li className="leverage-heading">
                        <strong>Volume</strong>
                        <strong>Leverage</strong>
                      </li>
                      {Array.isArray(leverage) &&
                        leverage.map((lvg, index) => {
                          return (
                            <li key={index}>
                              <span>{lvg.exposure_level} </span>{" "}
                              <span>1:{lvg.available_leverage} </span>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={`order-book-wrapper ${domClass}`}>
        <OrderBook />
      </div>
      <div className={`settings-right-panel ${settingsClass}`}>
        <div className="font-adjuster">
          <h2>Adjust Font Size</h2>
          <ul>
            <li>
              <label
                htmlFor="largeFontRadio"
                role={font === "Large" ? "true" : "false"}
              >
                <input
                  onChange={(e) => fontAdjuster(e)}
                  type="radio"
                  name="fontAdjuster"
                  value="Large"
                  id="largeFontRadio"
                  hidden
                />
                <span>Large</span>
                <div className="custom-radio-box"></div>
              </label>
            </li>
            <li>
              <label
                htmlFor="defaultFontRadio"
                role={font === "Default" ? "true" : "false"}
              >
                <input
                  onChange={(e) => fontAdjuster(e)}
                  type="radio"
                  name="fontAdjuster"
                  value="Default"
                  id="defaultFontRadio"
                  hidden
                />
                <span>Default</span>
                <div className="custom-radio-box"></div>
              </label>
            </li>
            <li>
              <label
                htmlFor="smallFontRadio"
                role={font === "Small" ? "true" : "false"}
              >
                <input
                  onChange={(e) => fontAdjuster(e)}
                  type="radio"
                  name="fontAdjuster"
                  value="Small"
                  id="smallFontRadio"
                  hidden
                />
                <span>Small</span>
                <div className="custom-radio-box"></div>
              </label>
            </li>
          </ul>
        </div>
        <div className="font-adjuster -setting-panel-divider">
          <h2>Toggle Position Markers</h2>
          <ul>
            <li>
              <label
                htmlFor="openPosition"
                role={positionMarker === "open" ? "true" : "false"}
              >
                <input
                  onChange={() => setPositionMarkerValue('open')}
                  type="radio"
                  id="openPosition"
                  name="positionMarker"
                  hidden
                />
                <span>Position Open</span>
                <div className="custom-radio-box"></div>
              </label>
            </li>
            <li>
              <label
                htmlFor="closePosition"
                role={positionMarker === "close" ? "true" : "false"}
              >
                <input
                  onChange={() => setPositionMarkerValue('close')}
                  type="radio"
                  id="closePosition"
                  name="positionMarker"
                  hidden
                />
                <span>Position Close</span>
                <div className="custom-radio-box"></div>
              </label>
            </li>
            <li>
              <label
                htmlFor="showAll"
                role={positionMarker === "show" ? "true" : "false"}
              >
                <input
                  onChange={() => setPositionMarkerValue('show')}
                  type="radio"
                  id="showAll"
                  name="positionMarker"
                  hidden
                />
                <span>Show all markers</span>
                <div className="custom-radio-box"></div>
              </label>
            </li>
            <li>
              <label
                htmlFor="hideAll"
                role={positionMarker === "hide" ? "true" : "false"}
              >
                <input
                  onChange={() => setPositionMarkerValue('hide')}
                  type="radio"
                  id="hideAll"
                  name="positionMarker"
                  hidden
                />
                <span>Hide all markers</span>
                <div className="custom-radio-box"></div>
              </label>
            </li>
          </ul>
        </div>
        <div className="font-adjuster -setting-panel-divider">
          <h2>Toggle Candle's Tooltip</h2>
          <ul>
            <li>
              <label
                htmlFor="showTooltip"
                role={toggleTooltip === "show" ? "true" : "false"}
              >
                <input
                  onChange={() => {setToggleTooltip('show');localStorage.setItem('chartTooltips','show');}}
                  type="radio"
                  id="showTooltip"
                  name="toggleTooltip"
                  hidden
                />
                <span>Show</span>
                <div className="custom-radio-box"></div>
              </label>
            </li>
            <li>
              <label
                htmlFor="hideTooltip"
                role={toggleTooltip === "hide" ? "true" : "false"}
              >
                <input
                  onChange={() => {setToggleTooltip('hide');localStorage.setItem('chartTooltips','hide');}}
                  type="radio"
                  id="hideTooltip"
                  name="toggleTooltip"
                  hidden
                />
                <span>Hide</span>
                <div className="custom-radio-box"></div>
              </label>
            </li>
          </ul>
        </div>
        <div className="reset-settings">
          <h2>Reset all the changes</h2>
          <button onClick={resetChanges}>Reset</button>
        </div>
      </div>
    </>
  );
};

export default OrderPanel;
