import React, { useState } from "react";
import { FaSignOutAlt } from "react-icons/fa";
import { useThemeContext } from "../../../contexts/Theme-Context";
import { useAuthContext } from "../../../contexts/Auth-Context";
import { useSymbolContext } from "../../../contexts/Symbol-Context";
import { useMetricsContext } from '../../../contexts/Metrics-Context';
//import LoginWrapper from '../../utils/wrappers/LoginWrapper'
import "./UserSettings.scss";
import { useNavigate } from "react-router-dom";

const UserSettings = () => {
  //CONTEXT
  const { setTheme, theme, profileImage, setProfileImage } = useThemeContext();
  const { user, logout, login } = useAuthContext();
  const { mkRipple } = useSymbolContext();
  const { metrics } = useMetricsContext();
  const navigate = useNavigate();

  const [showLoginModal, setShowLoginModal] = useState(false);

  const handleThemeChange = (themeKey) => {
    setTheme(themeKey);
  };

  const handleLoginClick = () => {
    //if user already logged in then don't need to sho login page/modal
    if (user && user.userId != undefined) {
      login(user.userId, user.userName, user.balance);
    } else {
      setShowLoginModal(true);
    }
  };
  const clearAllThenLogout = async () => {
    await logout();
    navigate('/');
  };
   
  const changeProfileImage=(e)=>{
    setProfileImage(window.URL.createObjectURL(e.target.files[0]));
    window.URL.revokeObjectURL(e.target.files[0]);
  }

  return (
    <>
      <div className="user-container">
        <div className="user-data-nd-settings">
          <div className="user-profile-picture">
            <input type="file" hidden id="imgSetter" onChange={(e)=>changeProfileImage(e)} accept="image/.png,.jpeg,.jpeg,.webp,.heic,.bmp,.ico,.png" />
            <label htmlFor="imgSetter"><img src={profileImage} alt="profile picture" /></label>
          </div>
          <div className="settings-nd-name">
            <h2 id="textBoldColor" >{user?.userName || ''}</h2>
            <h2 id="textBoldColor" >{user?.userAccountId || ''}</h2>
            {/* <div className="theme-switcher">
              <div className="theme-switch-setup" >
                <div className="p">
                  <span id="currentTheme" >{theme}</span> Theme
                  <svg
                    viewBox="0 0 14 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L7 7L13 1"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <div className="theme-collection">
                    <div className="theme-key">
                      <p>Dark Theme</p>
                      <div
                        className="radio-switch"
                        role="dark"
                        onClick={() => handleThemeChange("dark")}
                      >
                        <div className="indication-ball"></div>
                      </div>
                    </div>
                    <div className="theme-key">
                      <p>Light Theme</p>
                      <div
                        className="radio-switch"
                        role="light"
                        onClick={() => handleThemeChange("light")}
                      >
                        <div className="indication-ball"></div>
                      </div>
                    </div>
                    <div className="theme-key">
                      <p>Ocean Theme</p>
                      <div
                        className="radio-switch"
                        role="ocean"
                        onClick={() => handleThemeChange("ocean")}
                      >
                        <div className="indication-ball"></div>
                      </div>
                    </div>
                    <div className="theme-key">
                      <p>Venom Theme</p>
                      <div
                        className="radio-switch"
                        role="venom"
                        onClick={() => handleThemeChange("venom")}
                      >
                        <div className="indication-ball"></div>
                      </div>
                    </div>
                    <div className="theme-key">
                      <p>Skyline Theme</p>
                      <div
                        className="radio-switch"
                        role="skyline"
                        onClick={() => handleThemeChange("skyline")}
                      >
                        <div className="indication-ball"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        {
          <div className="user-info-container">
            <button
              className="login-button"
              onClick={(e) => { mkRipple(e); clearAllThenLogout(); }}
            >
              Log out
            </button>
          </div>
        }

      </div>
      {/* <div className="user-balance">
        <p>Balance: <span>{metrics.balance?.toFixed(2)} {user?.userCurrencyName || 'USD'}</span></p>

      </div> */}
      <div className="user-balance">
        <p>Equity: <span>{metrics.equity?.toFixed(2)}</span></p>

      </div>
    </>
  );
};

export default UserSettings;
