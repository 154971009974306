import React, { useEffect, useState } from "react";
import Chart from "../../components/main/chart/Chart";
import Symbol from "../../components/main/symbol/Symbol";
import OrderPanel from "../../components/main/order_panel/Order-Panel";
import OrderBook from "../../components/main/order_book/Order-Book";
import AccountManager from "../../components/main/account_manager/Account-Manager";
import UserSettings from "../../components/main/user_settings/UserSettings";
import MetricsPanel from "../../components/main/metrics_panel/Metrics-Panel";
import { useRippleContext } from "../../contexts/Ripple-Context";
// import guide from "../../imgs/guide.png";
// import installImg from "../../imgs/install.png";
import "./Main.scss";
import "../../themes/common.scss";
import "../../themes/dark.scss";
import "../../themes/light.scss";
import "../../themes/ocean.scss";
import "../../themes/venom.scss";
import "../../themes/skyline.scss";
import { useOrderContext } from "../../contexts/Order-Context";
import { GoDiscussionOutdated } from "react-icons/go";
import { useAuthContext } from "../../contexts/Auth-Context";
import { useNavigate } from "react-router-dom";
import APIMiddleware from "../../data/api/Api-Middleware";
import openSound from '../../audio/open-beep.mp3';
import closeSound from '../../audio/close-beep.mp3';
import {
  API_ENDPOINT_LOGIN,
  API_ENDPOINT_USER_DETAILS,
} from "../../data/Endpoints-API";

const Main = () => {
  let toggleAccountManagerLocal;
  if(localStorage.bottomElementBtn !== undefined){
    toggleAccountManagerLocal = localStorage.bottomElementBtn;
  }else{
    toggleAccountManagerLocal = false;
  }
  const [toggleAccountManagerPanel,setToggleAccountManagerPanel] = useState(toggleAccountManagerLocal);
  const toggleAccountManager=(e)=>{
    if(toggleAccountManagerPanel === true){
      document.querySelector('.bottom-section').setAttribute('aria-hidden', true)
      localStorage.setItem('bottomElement', true);
      const chartFullHeight = parseInt(getComputedStyle(document.querySelector('.main-screen')).height.split('px')[0]) - 115;
      document.querySelector(".chart-box").style.height = `${chartFullHeight}px`;
      localStorage.setItem('chartHeight', `${chartFullHeight}px`);
      localStorage.setItem('bottomElementBtn',false);
      setToggleAccountManagerPanel(false);
    }else{
      setToggleAccountManagerPanel(true);
      localStorage.setItem('bottomElementBtn',true);
      document.querySelector(".chart-box").style.height = `40px`;
      localStorage.setItem('chartHeight', `40px`);
      document.querySelector('.bottom-section').setAttribute('aria-hidden', false)
      localStorage.setItem('bottomElement', false);
    }  }

  const { login } = useAuthContext();
  const navigate = useNavigate();

  const [chartHeightState, setChartHeightState] = useState('60vh');
  useEffect(() => {
    if (localStorage.getItem('chartHeight') != null) {
      setChartHeightState(localStorage.getItem('chartHeight'));
    }
  }, [localStorage.getItem('chartHeight')]);
  const { setAppElements, appElements, font, setFont } = useOrderContext();
  setTimeout(() => {
    let allElements = document.querySelectorAll(
      `button, h6, h5, h4, 
      h3, h2, h1, p, span, strong, 
      label, .bstorm-body-tr, .tab-button-acc, 
      td, .mode-tab-button, li, .z-top`
    );
    setAppElements(allElements);
  }, 2000);

  const zoomClicker = (e) => {
    const rangebar = document.getElementById('zoomRanger');
    const currentValue = parseFloat(rangebar.value);
    const step = parseFloat(rangebar.step);
    let newValue;
    if (e === '+') {
      newValue = currentValue + step;
    } else {
      newValue = currentValue - step;
    }
    rangebar.value = newValue.toString();
    const event = new Event('change');
    rangebar.dispatchEvent(event);
  }

  const defineCurrentFont = (hook) => {
    const preFont = localStorage.currentFont;
    if (preFont == undefined) {
      localStorage.setItem("currentFont", "Default");
    } else {
      localStorage.setItem("currentFont", hook);
    }
    const setFont = (e, o) => {
      for (let i = 0; i < appElements.length; i++) {
        let size = parseInt(
          getComputedStyle(appElements[i]).fontSize.split("px")[0]
        );
        if (o == "+") {
          appElements[i].style.fontSize = `${(size += e)}px`;
          console.log('Plus');
        } else {
          appElements[i].style.fontSize = `${(size -= e)}px`;
          console.log('Minus');
        }
      }
    };

    if (
      (preFont == "Default" && hook == "Large") ||
      (preFont == "Small" && hook == "Default")
    ) {
      setFont(1, "+");
    }
    if (
      (preFont == "Large" && hook == "Default") ||
      (preFont == "Default" && hook == "Small")
    ) {
      setFont(1, "-");
    }

    if (preFont == "Large" && hook == "Small") {
      setFont(2, "-");
    }
    if (preFont == "Small" && hook == "Large") {
      setFont(2, "+");
    }
  };

  useEffect(() => {
    defineCurrentFont(font);
  }, [font]);

  useEffect(() => {

    const autoLogin = async () => {
      const userId = localStorage.getItem("userId");
      if (
        userId &&
        userId !== "undefined" &&
        userId != undefined &&
        userId > 0
      ) {
        const data = {
          userId,
        };
        const response = await APIMiddleware.post(
          API_ENDPOINT_USER_DETAILS,
          data
        );
        if (response.data[0]) {
          const d = response.data[0];
          login(d);
        }
      }
    };


    autoLogin();

  }, []);

  const panelExpander = (element) => {
    let panelExpandStatus = true;
    window.onmouseup = () => {
      panelExpandStatus = false;
    };
    window.onmousemove = (m) => {
      if (panelExpandStatus == true) {
        if (element == "left-nav") {
          const panel = document.getElementsByClassName(element)[0];
          const preWidth = parseInt(getComputedStyle(panel).width.split("px")[0]);
          const maxWidth = 600;
          if (preWidth < m.x && m.x < maxWidth) {
            panel.style.width = `${m.x}px`;
          }
        }
        if ((window.innerWidth - m.x) > 0 && element == "right-nav") {
          const rightX = window.innerWidth - m.x;
          const panel = document.getElementsByClassName(element)[0];
          const preWidth = parseInt(getComputedStyle(panel).width.split("px")[0]);
          const maxWidth = 700;
          if (preWidth < rightX && rightX < maxWidth) {
            panel.style.width = `${rightX}px`;
          }
        }
      }
    };
  };

  const dragWm = (element) => {
    let wmDrag = true;
    window.onmouseup = () => {
      wmDrag = false;
    };
    window.onmousemove = (position) => {
      if (wmDrag == true && window.innerWidth >= 700) {
        const elemSize = element.target.parentNode.getClientRects()[0];
        if (elemSize.width + elemSize.left <= window.innerWidth) {
          element.target.parentNode.style.left = `${position.x - elemSize.width / 2
            }px`;
        } else {
          element.target.parentNode.style.left = `${window.innerWidth - elemSize.width - 1
            }px`;
        }
        if (elemSize.height + elemSize.top <= window.innerHeight) {
          element.target.parentNode.style.top = `${position.y - (elemSize.height - (elemSize.height - 10))
            }px`;
        } else {
          element.target.parentNode.style.top = `${window.innerHeight - elemSize.height - 1
            }px`;
        }
      }
    };
  };

  const exitWm = (e) => {
    e.target.parentNode.parentNode.parentNode.setAttribute(
      "aria-modal",
      "false"
    );
  };

  const guideExit = () => {
    document.querySelector(".mob-guide").setAttribute("hide", "true");
  };
  const guideStart = () => {
    document.querySelector(".mob-guide").setAttribute("hide", "false");
  };

  const depositRedirect = (e) => {
    const action = e.target.getAttribute("action");
    if (action == "exit") {
      e.target.parentNode.parentNode.parentNode.setAttribute("view", false);
    } else {
      e.target.parentNode.parentNode.parentNode.setAttribute("view", false);
      window.open(action, "_blank");
    }
  };
  let preLeftPanelState;
  if (localStorage.getItem('left-nav') == null) {
    preLeftPanelState = 'show';
  } else {
    preLeftPanelState = localStorage.getItem('left-nav');
  }
  let preRightPanelState;
  if (localStorage.getItem('right-nav') == null) {
    preRightPanelState = 'show';
  } else {
    preRightPanelState = localStorage.getItem('right-nav');
  }
  const [rightPanel, setRightPanel] = useState(preRightPanelState);
  const [leftPanel, setLeftPanel] = useState(preLeftPanelState);
  const sidePanelSwitch = (element) => {
    const attr = document.getElementsByClassName(element)[0].getAttribute('role');
    if (element == 'right-nav') {
      if (attr == 'show') {
        setRightPanel('hide');
        localStorage.setItem(element, 'hide');
      } else {
        setRightPanel('show');
        localStorage.setItem(element, 'show');
      }
    }
    if (element == 'left-nav') {
      if (attr == 'show') {
        setLeftPanel('hide');
        localStorage.setItem(element, 'hide');
      } else {
        setLeftPanel('show');
        localStorage.setItem(element, 'show');
      }
    }
  };
  const windowsReadyState = () => {
    if (localStorage.zoomRange === undefined) {
      localStorage.setItem('zoomRange', 0.9);
    }
    if (localStorage.bottomElement !== undefined) {
      document.querySelector('.bottom-section').setAttribute('aria-hidden', localStorage.bottomElement);
    }
    document.body.style.display = "block";
    const div = document.querySelector(".chart-box");
    const childChart = (width, height) => {
      const screenHeight = document
        .querySelector(".left-nav")
        .getClientRects()[0].height;
      if (document.body.className == 'theme-skyline') {
        document.querySelector(".height-24vh-css").style.height = `${screenHeight - height - 100}px`;
      } else {
        document.querySelector(".height-24vh-css").style.height = `${screenHeight - height - 115}px`;
      }
      document.querySelector("#chartdiv").style.height = `${height}px`;
      document.querySelector(
        ".chart-box"
      ).children[0].children[0].children[1].children[0].children[0].children[0].children[1].style.width = `${width}px`;
      document.querySelector(
        ".chart-box"
      ).children[0].children[0].children[1].children[0].children[0].children[0].children[0].style.width = `${width}px`;
      document.querySelector(
        ".chart-box"
      ).children[0].children[0].children[1].children[0].children[0].children[0].children[1].style.height = `${height}px`;
      document.querySelector(
        ".chart-box"
      ).children[0].children[0].children[1].children[0].children[0].children[0].children[0].style.height = `${height}px`;
    };

    const handleResize = (entries) => {
      for (const entry of entries) {
        if (document.querySelector(".chart-box")) {
          const chartParent = document
            .querySelector(".chart-box")
            .getClientRects()[0];
          childChart(chartParent.width, chartParent.height);
        }
      }
    };
    const chartParent = document
      .querySelector(".chart-box")
      .getClientRects()[0];
    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(div);
    const resizeDirection =
      document.querySelector("#resizeHistory").children[0];
    const resizeHistory = document.getElementById("resizeHistory");
    let isResizing = false;
    resizeHistory.addEventListener("mousedown", () => {
      isResizing = true;
    });
    resizeHistory.addEventListener("click", () => {
      if (
        resizeDirection.style.transform == "rotateX(180deg)" ||
        resizeDirection.style.transform == ""
      ) {
        if (document.body.className == 'theme-skyline') {
          document.querySelector(".chart-box").style.height = `67px`;
          localStorage.setItem('chartHeight', `67px`);
        } else {
          document.querySelector(".chart-box").style.height = `40px`;
          localStorage.setItem('chartHeight', `40px`);
        }
        setTimeout(() => {
          resizeDirection.style.transform = "rotateX(0deg)";
        }, 500);
        document.querySelector('.bottom-section').setAttribute('aria-hidden', false);
        localStorage.setItem('bottomElement', false);
      } else {
        document.querySelector('.bottom-section').setAttribute('aria-hidden', true)
        localStorage.setItem('bottomElement', true);
        // const chartFullHeight = parseInt(getComputedStyle(document.querySelector('.main-screen')).height.split('px')[0])-80; --- for old dark-scss
        const chartFullHeight = parseInt(getComputedStyle(document.querySelector('.main-screen')).height.split('px')[0]) - 115;
        document.querySelector(".chart-box").style.height = `${chartFullHeight}px`;
        localStorage.setItem('chartHeight', `${chartFullHeight}px`);
        setTimeout(() => {
          resizeDirection.style.transform = "rotateX(180deg)";
        }, 500);
      }
    });
  


    let preY = 0;
    window.addEventListener("mousemove", (event) => {
      if (isResizing == true && event.clientY <= 395 && event.clientY >= 39) {
        document.querySelector('.bottom-section').setAttribute('aria-hidden',false)
        localStorage.setItem('bottomElement',false);
        childChart(chartParent.width, event.clientY);
        document.querySelector(
          ".chart-box"
        ).style.height = `${event.clientY}px`;
        localStorage.setItem('chartHeight', `${event.clientY}px`);
        if (event.clientY > preY) {
          resizeDirection.style.transform = "rotateX(0deg)";
        } else if (event.clientY < preY) {
          resizeDirection.style.transform = "rotateX(180deg)";
        }
      }

      preY = event.clientY;
    });
    window.addEventListener("mouseup", () => {
      isResizing = false;
    });
  };

  useEffect(() => {
    const wiseWatcher = setInterval(() => {
      if (document.querySelector(".am5stock") != null) {
        clearInterval(wiseWatcher);
        windowsReadyState();
      }
    }, 500);

    return () => clearInterval(wiseWatcher);
  }, []);

  const { mkRipple } = useRippleContext();
  const mobMenu = (e) => {
    document.body.setAttribute("mobileCurrentView", e);
  };
  const candleColorPicker = (e) => {
    e.target.style.backgroundColor = e.target.value;
    if (e.target.getAttribute("id") == "chartUpColor") {
      document.getElementById(
        "upCandle-color-indication"
      ).style.backgroundColor = e.target.value;
    } else {
      document.getElementById(
        "downCandle-color-indication"
      ).style.backgroundColor = e.target.value;
    }
  };
  return (
    <>
      <audio src={openSound} hidden id="openSound" ></audio>
      <audio src={closeSound} hidden id="closeSound" ></audio>
      <div className="wm-parent" aria-modal="false">
        <div className="window-module">
          <div className="wm-header" onMouseDown={(e) => dragWm(e)}>
            <h2 className="wmTitleDynamic"></h2>
            <div
              className="wm-exit"
              onClick={(e) => {
                exitWm(e);
              }}
            >
              X
            </div>
          </div>
          <div className="wm-content">
            <div className="content-wm-title">
              <h1 className="wmTitleDynamic"></h1>
              <p className="wmDescriptionDynamic"></p>
            </div>
            <div className="input-content-wm">
              <div className="row">
                <div className="wm-read-p-in mx">
                  <p>Position ID Information</p>
                  <input type="text" readOnly />
                </div>
              </div>
            </div>
            <div className="input-content-wm">
              <div className="row">
                <div className="wm-read-p-in">
                  <p>Margin</p>
                  <input type="text" readOnly />
                </div>
                <div className="wm-read-p-in">
                  <p>Entry</p>
                  <input type="text" readOnly />
                </div>
              </div>
              <div className="row">
                <div className="wm-read-p-in">
                  <p>Current</p>
                  <input type="text" readOnly />
                </div>
                <div className="wm-read-p-in">
                  <p>PNL</p>
                  <input type="text" readOnly />
                </div>
              </div>
            </div>
            <div className="input-content-wm">
              <div className="row">
                <div className="wm-read-p-in mx">
                  <p>Created Date and Time</p>
                  <input type="text" readOnly />
                </div>
              </div>
            </div>
            <div className="input-content-wm">
              <div className="row">
                <div className="wm-read-p-in">
                  <p>Quantity</p>
                  <input type="text" readOnly />
                </div>
                <div className="wm-read-p-in">
                  <p>Direction</p>
                  <input type="text" readOnly />
                </div>
              </div>
              <div className="row">
                <div className="wm-read-p-in mx">
                  <p>Symbol Name</p>
                  <input type="text" readOnly />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="download-now" onClick={guideStart}>
        Install
      </div>
      <div className="mob-guide" hide="true">
        <img src={'./guide.png'} alt="Mobile Guide" />
        <button onClick={guideExit}>ok, got it</button>
      </div> */}
      <div id="divMain" className="row" aria-atomic="default">
        <div className="deposit-cash">
          <div className="card">
            <h2>Unlock Your Financial Potential with Seamless Trading</h2>
            <div className="row-scss">
              <button
                onClick={(e) => {
                  mkRipple(e);
                  depositRedirect(e);
                }}
                action="exit"
              >
                Not now
              </button>
              <button
                onClick={(e) => {
                  mkRipple(e);
                  depositRedirect(e);
                }}
                action="https://backoffice.rxbt.net/user/new_deposit_request"
              >
                Deposit
              </button>
            </div>
          </div>
        </div>
        <div className="scr-lft-main">
          <div className="w-100-css">
            <div className="main-screen">
              <div className="user-header-mobile">
                <UserSettings />
              </div>
              <div className="left-nav" role={leftPanel}>
                <button className="panelSwitch">
                  <svg
                    onClick={(e) => sidePanelSwitch('left-nav')}
                    onMouseDown={(e) => panelExpander("left-nav")}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 15 36"
                  >
                    <path d="M6 5h1v5H6zM6 26h1v5H6zM8 5h1v5H8zM8 26h1v5H8zM9 18l-3-3v6Z"></path>
                  </svg>
                </button>
                <div className="user-details">
                  <UserSettings />
                </div>
                <div className="left-nav-shrink-true">
                  <svg onClick={(e) => sidePanelSwitch('left-nav')} viewBox="0 0 24 24">
                    <path d="M8 13v4H6v2h3v2h2v-2h2v2h2v-2.051c1.968-.249 3.5-1.915 3.5-3.949 0-1.32-.65-2.484-1.64-3.213A3.982 3.982 0 0018 9c0-1.858-1.279-3.411-3-3.858V3h-2v2h-2V3H9v2H6v2h2v6zm6.5 4H10v-4h4.5c1.103 0 2 .897 2 2s-.897 2-2 2zM10 7h4c1.103 0 2 .897 2 2s-.897 2-2 2h-4V7z" />
                  </svg>
                </div>
                <div className="">
                  <Symbol />
                </div>
              </div>
              <div className="col-dir" >
                <div className="chart-tabs" ></div>
                <div className="direction-col">
                  <div className="chart-zoom" >
                    {/* <input type="range" id="zoomRanger" min='0.2' step='0.1' max='1' /> */}
                    {/* <div className="zoom-btn">
                      <svg width="20" height="20" onClick={() => zoomClicker('-')} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M18.9999 18.9999L14.6499 14.6499" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M6 9H12" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </div>
                    <div className="zoom-btn">
                      <svg width="20" height="20" onClick={() => zoomClicker('+')} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M18.9999 18.9999L14.6499 14.6499" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M9 6V12" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M6 9H12" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </div> */}
                  </div>
                  {/* THis input will be used to store default colors from theme  */}
                  <div className="chart-color-picker">
                    <input
                      type="color"
                      id="chartUpColor"
                    />
                    <input
                      type="color"
                      id="chartDownColor"
                    />
                  </div>
                  <div className="chart-box"
                    style={{ height: chartHeightState }}
                  >
                    <div className="">
                      <Chart />
                    </div>
                  </div>
                  <div className="resize-history-position">
                    <button
                      id="resizeHistory"
                      title="Click to Expand the element in arrow direction. Hold and Drag this button to customize the height of specific element."
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 15">
                        <path d="M5 6h5v1H5z"></path>
                        <path d="M26 6h5v1h-5z"></path>
                        <path d="M5 8h5v1H5z"></path>
                        <path d="M26 8h5v1h-5z"></path>
                        <path d="m18 9 3-3h-6Z"></path>
                      </svg>
                    </button>
                    <div className="more-actions-right" >
                      <div title="Toggle your account manager panel" className="history-sceen-mode" onClick={(e)=>{toggleAccountManager(e)}}  aria-checked={toggleAccountManagerPanel} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-fullscreen" viewBox="0 0 16 16">
                          <path d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5M.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5m15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-fullscreen-exit" viewBox="0 0 16 16">
                          <path d="M5.5 0a.5.5 0 0 1 .5.5v4A1.5 1.5 0 0 1 4.5 6h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5m5 0a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 10 4.5v-4a.5.5 0 0 1 .5-.5M0 10.5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 6 11.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5m10 1a1.5 1.5 0 0 1 1.5-1.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0z" />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="bottom-section">
                    <div className="row-Bottom">
                      <div className="height-24vh-css">
                        <AccountManager />
                      </div>
                      <div className="metric-parent">
                        <MetricsPanel />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="right-nav" role={rightPanel}>
            <button className="panelSwitch">
              <svg
                onClick={(e) => sidePanelSwitch('right-nav')}
                onMouseDown={(e) => panelExpander("right-nav")}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 15 36"
              >
                <path d="M6 5h1v5H6zM6 26h1v5H6zM8 5h1v5H8zM8 26h1v5H8zM9 18l-3-3v6Z"></path>
              </svg>
            </button>
            {/* <div className="pro-look">
              <div className="my-col">
                <img src={'./install.png'} alt="Install Image" />
              </div>
              <div className="my-col">
                <div className="align-text">
                  <p>Fast, Free and Secure</p>
                  <h2>RXBT</h2>
                </div>
                <div className="in-btn">
                  <button
                    onClick={() =>
                      alert(
                        `1: Click on three dots ( top right corner ) - 2: Click on Save and share - 3: Click on Install.`
                      )
                    }
                  >
                    Install Now
                  </button>
                </div>
              </div>
            </div> */}
            <div className="in-right">
              <OrderPanel />
            </div>
          </div>
        </div>
        <div className="smart-brain-mobile-view">
          <div
            className="btn-icon-block"
            role="symbol"
            onClick={(e) => {
              mobMenu("symbol");
              mkRipple(e);
            }}
          >
            <svg
              viewBox="0 0 24 24"
              fill="currentColor"
              height="1em"
              width="1em"
            >
              <path d="M8 13v4H6v2h3v2h2v-2h2v2h2v-2.051c1.968-.249 3.5-1.915 3.5-3.949 0-1.32-.65-2.484-1.64-3.213A3.982 3.982 0 0018 9c0-1.858-1.279-3.411-3-3.858V3h-2v2h-2V3H9v2H6v2h2v6zm6.5 4H10v-4h4.5c1.103 0 2 .897 2 2s-.897 2-2 2zM10 7h4c1.103 0 2 .897 2 2s-.897 2-2 2h-4V7z" />
            </svg>
            <p>Symbol</p>
          </div>
          <div
            className="btn-icon-block"
            role="chart"
            onClick={(e) => {
              mobMenu("chart");
              mkRipple(e);
            }}
          >
            <svg
              viewBox="0 0 21 21"
              fill="currentColor"
              height="1em"
              width="1em"
            >
              <g
                fill="none"
                fillRule="evenodd"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M4.5 3.5v11a2 2 0 002 2h11" />
                <path d="M6.5 12.5l3-3 2 2 5-5" />
                <path d="M16.5 9.5v-3h-3" />
              </g>
            </svg>
            <p>Chart</p>
          </div>
          <div
            className="btn-icon-block"
            role="home"
            onClick={(e) => {
              mobMenu("home");
              mkRipple(e);
            }}
          >
            <svg
              viewBox="0 0 24 24"
              fill="currentColor"
              height="1em"
              width="1em"
            >
              <path d="M5 22h14a2 2 0 002-2v-9a1 1 0 00-.29-.71l-8-8a1 1 0 00-1.41 0l-8 8A1 1 0 003 11v9a2 2 0 002 2zm5-2v-5h4v5zm-5-8.59l7-7 7 7V20h-3v-5a2 2 0 00-2-2h-4a2 2 0 00-2 2v5H5z" />
            </svg>
            <p>Home</p>
          </div>
          <div
            className="btn-icon-block"
            role="trading"
            onClick={(e) => {
              mobMenu("trading");
              mkRipple(e);
            }}
          >
            <svg
              viewBox="0 0 24 24"
              fill="currentColor"
              height="1em"
              width="1em"
            >
              <path d="M16.146 0v24l6.134-4.886V3.334zm-2.853 18.758l-4.939 2.157V2.086l4.939 1.462zm-11.572-.553l3.78-.999V5.188l-3.762-.606z" />
            </svg>
            <p>Trading</p>
          </div>
          <div
            className="btn-icon-block"
            role="history"
            onClick={(e) => {
              mobMenu("history");
              mkRipple(e);
            }}
          >
            <svg
              viewBox="0 0 24 24"
              fill="currentColor"
              height="1em"
              width="1em"
            >
              <path d="M16.727 3.18C12.31.81 6.915 2.103 4 6V3.5a.5.5 0 00-1 0v4a.5.5 0 00.5.5h4a.5.5 0 000-1H4.522a8.954 8.954 0 017.411-4A8.967 8.967 0 113 12c0-.16 0-.312.009-.472A.5.5 0 002.52 11c-.27-.01-.5.2-.51.472C2 11.652 2 11.82 2 12c.006 5.52 4.48 9.994 10 10a10.005 10.005 0 008.81-5.273c2.614-4.868.786-10.933-4.083-13.547zM12 8a.5.5 0 00-.5.5V12a.5.5 0 00.5.5h2.5a.5.5 0 000-1h-2v-3A.5.5 0 0012 8z" />
            </svg>
            <p>History</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
