// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import { API_ENDPOINT_SYMBOLS ,API_ENDPOINT_LOGOUT} from "../data/Endpoints-API";
import { WS_ENDPOINT_PLATFORM_LIVE_FEEDS } from "../data/Endpoints-WS";
import APIMiddleware from "../data/api/Api-Middleware";
import { ws_close_all, ws_create } from "../data/websocket/Websocket-Middleware";
import { useSymbolContext } from "./Symbol-Context";

export const AuthContext = createContext();

export function useAuthContext() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {

  const [user, setUser] = useState({
    userId: 0,
    userName: "",
    total_deposit: 0,
    total_withdrawal: 0,
    total_margin: 0,
    total_realized_pnl: 0,
    tempData: "",
    isAuthorized: false,
  });

  const [selectedAuthSymbol, setAuthSelectedSymbol] = useState('');
  const [defaultSelectedSymbol, setDefaultSelectedSymbol] = useState('');
  const [defaultSelectedCategory, setDefaultSelectedCategory] = useState('');
  const [selectedAuthTimeFrame, setAuthTimeFrame] = useState('1m');
  const [authSelectedCategory, setAuthSelectedCategory] = useState(1);
  const [allValidSymbols, setAllValidSymbols] = useState([]);

  //create web-socket instance for the component
  const ws_platform = ws_create();
  const WS_MESSAGE_EVENT_LISTENER = "ws_message_platform";
  // const data = [];

    const [platFromData, setPlatFromData] = useState([[], [], [], [], [], []]); // Initial data state


  const updateData = (index, newData) => {
    setPlatFromData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = newData;
      return updatedData;
    });
  };

  useEffect(() => {
    if (user && user.userId != undefined && user.userId > 0) {
      getAllValidSymbols()
  }
  }, [user?.userId]);

  useEffect(() => {
    if (authSelectedCategory&&selectedAuthSymbol &&user && user.userId != undefined && user.userId > 0) {
      console.log("on load", user);
      // ws_platform.ws_disconnect(WS_ENDPOINT_PLATFORM_LIVE_FEEDS(user?.userId, localStorage.symbol, 10, '100ms', localStorage.timeFrame, localStorage.variantId),
      // WS_MESSAGE_EVENT_LISTENER);
      loadPlatform(user);
  }
  }, [selectedAuthSymbol, selectedAuthTimeFrame, allValidSymbols]);

  const getAllValidSymbols = async () => {
    // get symbols by api
    const response = await APIMiddleware.get(API_ENDPOINT_SYMBOLS(user.userId));
    if (response.data) {

      setAllValidSymbols(response.data);
      setDefaultSelectedSymbol(response.data[0][0].name)
     setDefaultSelectedCategory(response.data[0][0].symbol_category)
    }
  }

  const login = (userObj) => {
    // Implement login logic, e.g., by making an API request to server.
    // If login is successful, set the user.
    setUser(userObj);
    localStorage.setItem('accountManager', 'open-positions-acc');
    // loadPlatform(userObj);
  };

  const updateUserAuthorization = () => {
    user.isAuthorized = !user.isAuthorized;
  };

  const updateUserData = (updatedUserAccountData) => {
    if (user && user.userId != undefined && user.userId > 0) {
      // If the user is logged in, update account data
      setUser(updatedUserAccountData);
    }
  };

  const updateUserData_Local = (value) => {
    setUser((prevUserData) => ({
      ...prevUserData,
      tempData: value,
    }));
  };

  
  
  const loadPlatform = async (user) => {
    if (user && user.userId != undefined && user.userId > 0) {
        // get symbols by api
        // const response = await APIMiddleware.get(API_ENDPOINT_SYMBOLS(user.userId));

        // if (defaultSelectedSymbol) {
          // Extracting symbols names
          let selectedSymbolName;
          let selectedCategory;
          let selectedSymbolTimeFrame;
          // const symbolNames = response.data[0].map((symbol) => symbol.name);
          if (localStorage.symbol == null ||localStorage.category == null) {
            selectedSymbolName = defaultSelectedSymbol;
            selectedCategory = defaultSelectedCategory;
            console.log(defaultSelectedCategory,"defaultSelectedCategory")
          } 
          // else if (selectedAuthSymbol){
          //   selectedSymbolName = selectedAuthSymbol;
          // } 
          else {
            selectedSymbolName = localStorage.symbol;
            selectedCategory = localStorage.category;
          }

          if (localStorage.timeFrame == null) {
            selectedSymbolTimeFrame = selectedAuthTimeFrame;
            localStorage.setItem('timeFrame', selectedAuthTimeFrame);
          }
          else {
            selectedSymbolTimeFrame = localStorage.timeFrame;
          }

          if (selectedSymbolName) {
            //ws://localhost:8800/platform@BTCUSDT&10&1000ms&1m?id=8&variant_id=1
            //ws://localhost:8800/platform@ETCUSDT&10&100ms&1m?id=0&variant_id=1
              if(localStorage.variantId && localStorage.token){
                  ws_platform.ws_connect(
                    WS_ENDPOINT_PLATFORM_LIVE_FEEDS(user?.userId, selectedSymbolName, 10, '100ms', selectedSymbolTimeFrame, localStorage.variantId, selectedCategory,localStorage.token),
                    WS_MESSAGE_EVENT_LISTENER
                  );
              }             

            const handleLiveFeedData = (event) => {
              // Update data based on event detail
              if (event.detail.type === 'symbols') {
                updateData(0, event.detail.updatedSymbolData);
              } else if (event.detail.type === 'klines') {
                updateData(1, event.detail);
              } else if (event.detail.type === 'depth') {
                updateData(2, event.detail);
              } else if (event.detail.type === 'positions') {
                updateData(3, event.detail);
              } else if (event.detail.type === 'orders') {
                updateData(4, event.detail);
              } else if (event.detail.type === 'user_details') {
                updateData(5, event.detail);
              }
            };

            document.addEventListener(WS_MESSAGE_EVENT_LISTENER, handleLiveFeedData);
          } else {
            console.error("SYmbol not selected");
          }
        // }
    }
  };


  const sendDataToServer = (data) => {
    ws_platform.ws_send(
      WS_ENDPOINT_PLATFORM_LIVE_FEEDS(user?.userId, localStorage.symbol
        , 10, '100ms', localStorage.timeFrame, localStorage.variantId,authSelectedCategory),
      JSON.stringify(data)
    );
  };


  const logout = async() => {
    // Implement logout logic, e.g., by clearing user data or revoking tokens.
    const response = await APIMiddleware.get(API_ENDPOINT_LOGOUT);
    if (response ) {
      localStorage.removeItem("token");
    localStorage.removeItem("userId");
 
    if (localStorage.variantId != undefined) {
      localStorage.removeItem("variantId");
        }
    setUser(null);
    document.body.className = "";
    await ws_close_all();
    setTimeout(() => {
      window.location.reload();
    }, 300);
  }};
//if no activity perform in 30 min user auto logout
  useEffect(() => {
    let movementWatcher;
    if (user && user.userId != undefined && user.userId > 0) {
    const setReloadTime = () => {
      clearTimeout(movementWatcher);
      movementWatcher = setTimeout(() => { logout() }, 30 * 60 * 1000); 
    }
    window.onclick = () => { setReloadTime() };
    window.onkeydown = () => { setReloadTime() };
    setReloadTime();
  }

  }, [user?.userId])

  return (
    <AuthContext.Provider
      value={{
        allValidSymbols,
        user,
        login,
        logout,
        updateUserData,
        updateUserData_Local,
        updateUserAuthorization,
        loadPlatform,
        platFromData,
        sendDataToServer,
        selectedAuthSymbol, 
        setAuthSelectedSymbol,
        setAuthTimeFrame,
        selectedAuthTimeFrame,
        setAuthSelectedCategory,
        authSelectedCategory
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
