import React, { useState, useEffect } from "react";
import Spinner from "../../utils/spinner/Spinner";
import { toast } from "react-toastify";
import { useSymbolContext } from "../../../contexts/Symbol-Context.js";
import { API_ENDPOINT_OPEN_POSITION } from "../../../data/Endpoints-API.js";
import APIMiddleware from "../../../data/api/Api-Middleware.js";
import { useAccountManagerContext } from "../../../contexts/Account-Manager-Context.js";
import { useAuthContext } from "../../../contexts/Auth-Context.js";
import { useMetricsContext } from "../../../contexts/Metrics-Context.js";
import { useOrderContext } from "../../../contexts/Order-Context.js";
import { Position } from "../../../interfaces/Position.js";
import {
  formatPrice,
  formatPriceUptoDecimals,
  formatPositionToPipSize,
  formatDigitBasePrice,
} from "../../../utils/format.js";
import btcIcon from "../../../imgs/btc.png";
import usdtIcon from "../../../imgs/usdt.png";
import ProfitLoss from "./SLTP.jsx";

interface ChildProps {
  selectedOrderTab: any;
}

const MarketOrder: React.FC<ChildProps> = ({selectedOrderTab}) => {
  //CONTEXT
  const {
    loadingSymbolContext,
    bidPrice,
    askPrice,
    symbolInfo,
    marketHours,
    symbolData,
    updateSymbolData,
    allSymbolOptions,
    selectedSymbolOption,
    handleSymbolOptionChange,
    leverage,
    mkRipple,
    selectedSymbolSession,
    setSelectedSymbolSession,
    slPipsTotal,
    tpPipsTotal
  } = useSymbolContext();
  const { openPosition, updateUserData_Local, setActiveTab } =
    useAccountManagerContext();
  const { user, updateUserData, sendDataToServer ,selectedAuthSymbol} =
    useAuthContext();
  const { metrics } = useMetricsContext();
  const { exchangeRate, quoteExchangeRate } = useOrderContext();

  // #region All states defined here States
  const [pointerEvents, setPointerEvents] = useState("all");
  const [buyActive, setBuyActive] = useState(true);
  const [sellActive, setSellActive] = useState(false);
  const [direction, setDirection] = useState("Buy");
  const [quantity, setQuantity] = useState(0.0);
  const [amount, setAmount] = useState(0.0);
  const [entryPrice, setEntryPrice] = useState(0.0);
  const [expiresAt, setExpiresAt] = useState("");

  const [preDefineQuantities, setPreDefineQuantities] = useState("false");
  const [assetState, setAssetState] = useState({
    type: "",
    name: "",
  });
  const [orderComment, setOrderComment] = useState("");

  const [assetOptions,setAssetOptions] = useState('false');
  const currentAssetIcon = assetState.name === "USDT" ? usdtIcon : btcIcon;

  const [isPositionOpening, setIsPositionOpening] = useState(false);

  const [takeProfit, setTakeProfit] = useState(0.0);
  const [stopLoss, setStopLoss] = useState(0.0)  

  // #endregion


  useEffect(() => {
    // setPipSize(0.0001);
    // console.log("assetState", symbolInfo.base_asset);
    setAssetState({type: "base_asset",
    name: symbolInfo.base_asset});
    console.log(quoteExchangeRate)
  }, [symbolInfo]);

  useEffect(() => {
    //If amount entry price and quantity is not null or nan then it will pass to the condition
    if (!isNaN(amount) && !isNaN(entryPrice) && !isNaN(quantity)) {
      //If the selected type is base asset (BTC)
      //then it will set amount according to given quantity
      if (assetState.type === "base_asset") {
        setAmount(entryPrice * quantity);
      }
      //If the selected type is quote asset (USDT)
      //then it will set quantity according to given amount
      else if (assetState.type === "quote_asset") {
        setQuantity(amount / entryPrice);
      }
    }
  }, [amount, quantity, entryPrice]);

  useEffect(() => {
    setEntryPrice(
      direction == "Buy" ? parseFloat(askPrice) : parseFloat(bidPrice) 
    );
    // setEntryPrice(13.93300);
  }, [bidPrice, askPrice,  entryPrice]);

  const handleProfitChange = (data) => {
    setTakeProfit(data)
  };
  const handleLossChange = (data) => {
    setStopLoss(data)
  };

  const handleAssetChange = (e) => {
    // sltp states null on change
    setTakeProfit(0.0);
    setAmount(0.0);
    setQuantity(0.0);
    // Create a new object with updated values based on selection
    const updatedAssetState = {
      type: e === symbolInfo.base_asset ? "base_asset" : "quote_asset",
      name: e,
    };
    // Update the state using the updated object
    setAssetState(updatedAssetState);
  };

  const countLetters = (e) => {
    const counter = e.target.value.split("").length;
    const countBox = document.getElementById("count-comment-letters");
    if (counter <= 100 && countBox != null) {
      countBox.innerText = counter;
    } else {
      e.target.value = e.target.value.slice(0, 100);
    }
  };

  const handleBuySellOrderClick = (buy: boolean) => {
    setBuyActive(buy);
    setSellActive(!buy);
    setDirection(buy ? "Buy" : "Sell");
    setEntryPrice(buy ?   parseFloat(askPrice) : parseFloat(bidPrice));
  };

  const placeOrder = () => {
    if (user && user.userId != undefined && user.userId > 0) {
      setActiveTab("open-positions-acc");
      setIsPositionOpening(true);
      placeMarketOrder();
    }
  };

  const placeMarketOrder = () => {
    if ((buyActive || sellActive) && quantity > 0) {
      // Calculate required margin based on trading logic
      const requiredMargin = calculateRequiredMargin();
      // Check if the user has enough balance
      if (metrics.freeMargin >= requiredMargin) {
        // Continue with opening the position
        openPosition_api(requiredMargin);
      } else {
        // Show an alert for insufficient balance
        toast.error("Insufficient balance to open the position.", {
          position: "top-right",
        });
        setIsPositionOpening(false);
        document.getElementById("closeSound").play();
        document.querySelector(".deposit-cash")?.setAttribute("view", "true");
        const ig = document.getElementById("quantity-input-guide");
        ig?.setAttribute("shake", "true");
        setTimeout(() => {
          ig?.removeAttribute("shake");
        }, 2000);
      }
    } else {
      toast.error("Quantity should be greater than 0.", {
        position: "top-right",
      });
      document.getElementById("closeSound").play();
      setIsPositionOpening(false);
    }
  };

  //calculate the required margin before open the trade
  const calculateRequiredMargin = () => {
    const lvg =
      leverage[0]?.available_leverage > 0 ? leverage[0]?.available_leverage : 1;

    // const requiredMargin = quantity * (getEntryPrice() / lvg);
    const converted_entry_price = getEntryPrice() * exchangeRate;
    const requiredMargin = quantity * (converted_entry_price / lvg);
    return isNaN(requiredMargin) ? 0 : requiredMargin;
  };

  const getEntryPrice = () => (buyActive ? askPrice : bidPrice);

  //#region API CALLS
  const openPosition_api = async (margin) => {
    try {
      const currentDate = new Date();
      const currentDateTime = new Date(currentDate);

      const data: Position = {
        id: -1,
        position_id: "PID" + Math.floor(100000 + Math.random() * 900000),
        created_at: currentDateTime,
        symbol: selectedAuthSymbol,
        quantity: quantity,
        amount: amount,
        asset_type: assetState.type,
        direction: direction,
        entry_price: getEntryPrice(),
        converted_entry_price: getEntryPrice() * exchangeRate,
        TP: takeProfit,
        SL: stopLoss,
        netEUR: 0, // Set appropriate values
        status: "",
        userId: user.userId,
        // margin: margin,
        exit_price: 0,
        totalUnrealizedPnL: metrics.totalUnrealizedPnL,
        position_closed_at: null,
        comment: orderComment,
        stop_loss_pips: slPipsTotal,
        take_profit_pips: tpPipsTotal,
      };
      console.log(data)
      const response = await APIMiddleware.post(
        API_ENDPOINT_OPEN_POSITION,
        data
      );
      const data_m = {
        ...response.data,
        created_at: new Date(response.data.created_at).toLocaleString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }),
      };

      // Check if the response data matches the Position interface
      //if (Object.keys(response.data).every((key) => key in data)) {
      // openPosition(data_m);
      sendDataToServer(3);
      // updateUserData(response.data);
      //}

      // Show a success notification
      toast.success("Position opened successfully!", { position: "top-right" });
      setIsPositionOpening(false);
      localStorage.accountManager = "open-positions-acc";
      document.getElementById("openSound").play();
      const getCurrentDateTime = () => {
        var currentDate = new Date();
        var year = currentDate.getFullYear();
        var month = ("0" + (currentDate.getMonth() + 1)).slice(-2); // Adding 1 because months are zero-indexed
        var day = ("0" + currentDate.getDate()).slice(-2);
        var hour = ("0" + currentDate.getHours()).slice(-2);
        var minute = ("0" + currentDate.getMinutes()).slice(-2);
        var currentDateTime = `${year}-${month}-${day} ${hour}:${minute}:00`;
        return currentDateTime;
      };
      if (localStorage.chartBullets === undefined) {
        localStorage.setItem("chartBullets", getCurrentDateTime());
      } else {
        let history = [];
        history.push(localStorage.chartBullets);
        history.push(getCurrentDateTime());
        localStorage.chartBullets = history;
      }
    } catch (error) {
      // Handle API request error
      console.error(`API request error: ${API_ENDPOINT_OPEN_POSITION}`, error);
      setIsPositionOpening(false);
    }
  };

  const changeOrderTab = () => {
    localStorage.orderTab = "pending-order"
  }

  const [focusOut,setFocusOut] = useState(true);

  //#endregion

  //show spinner while loading data
  if (loadingSymbolContext) {
    return <Spinner />;
  }
  return (
    <>
    
      <div className="focusOutElement" aria-hidden={focusOut} onClick={()=>{
            setAssetOptions('false');
            setFocusOut(true)}} ></div>
      <div className="price-box-container">
        <div
          className={`price-box price-box-buy ${
            buyActive ? "price-box-buy-active" : ""
          }`}
          id="buyMarketOrder"
          onClick={() => handleBuySellOrderClick(true)}
        >
          <div>Buy</div>
          <div id="buyMarketPrice">
            {formatPriceUptoDecimals(askPrice, symbolInfo.digit)}
          </div>
        </div>
        <div
          className={`price-box price-box-sell ${
            sellActive ? "price-box-sell-active" : ""
          }`}
          id="sellMarketOrder"
          onClick={() => handleBuySellOrderClick(false)}
        >
          <div>Sell</div>
          <div id="sellMarketPrice">
            {formatPriceUptoDecimals(bidPrice, symbolInfo.digit)}
          </div>
        </div>
      </div>

      <label htmlFor="quantity" className="order-label">
        {assetState.type === "quote_asset" ? "Amount: " : "Quantity: "}
      </label>
      <div className="volume-amount-container">
        {assetState.type === "base_asset" ? (
          <div className="quantity-div">
            <input
              type="number"
              className="order-input"
              value={quantity}
              onChange={(e) => {
                setQuantity(parseFloat(e.target.value));
              }}
              onFocus={(e) => {
                  setAssetOptions('false');
              }}
            />
          </div>
        ) : (
          <div className="amount-div">
            <input
              type="number"
              className="amount-input"
              value={amount}
              onChange={(e) => {
                setAmount(parseFloat(e.target.value));
              }}
            />
          </div>
        )}
        <div className="-select-asset">
          <div className="asset-icon">
            {/* <img src={currentAssetIcon} /> */}
          </div>
          <div 
          className="selectedAsset"
          onClick={()=>{
            setAssetOptions('true');
            setFocusOut(false)}}
          >
            <p>{assetState.name}</p>
            <svg viewBox="0 0 16 16" aria-selected={assetOptions}>
  <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
</svg>
            </div>
        </div>
          <div className="custom-options-assets" aria-selected={assetOptions}>
            <div className="option-asset" onClick={()=>{handleAssetChange(symbolInfo.base_asset);setAssetOptions('false');setFocusOut(true);}}>
              <div className="left">
                {/* <img src={btcIcon} /> */}
                <p>{symbolInfo.base_asset}</p>
              </div>
            </div>
            <div className="option-asset" onClick={()=>{handleAssetChange(symbolInfo.quote_asset);setAssetOptions('false');setFocusOut(true);}}>
              <div className="left">
                {/* <img src={usdtIcon} /> */}
                <p>{symbolInfo.quote_asset}</p>
              </div>
            </div>
          </div>
      </div>
      <div className="reuqired-margin">
        <div className="title">Required Margin</div>
        <div className="value">
          {calculateRequiredMargin()}{" "}
          {user?.userCurrencyName || "USD"}
        </div>
      </div>
      {selectedOrderTab === "market"?
     <ProfitLoss handleProfitChange={handleProfitChange} handleLossChange={handleLossChange} direction={direction} quantity={quantity} entryPrice={entryPrice}/>
      :<></>}
      <div className="text-bx">
        <div className="text-area-letter-counter-sltp">
          <span id="count-comment-letters">0</span>/100
        </div>
        <textarea
          className="comment-sltp-lst"
          placeholder="Comment"
          onInput={(e) => {
            countLetters(e);
          }}
          value={orderComment}
          onChange={(e) => {
            setOrderComment(e.target.value);
          }}
        ></textarea>
      </div>

      <div className="place-order">
        {/* Conditionally set the disabled attribute based on the user's authentication status */}
        <button
          disabled={!bidPrice ||!askPrice && isPositionOpening || selectedSymbolSession === 0}
          className={`place-order-button ${
            buyActive ? "place-order-button-buy" : "place-order-button-sell"
          }`}
          title={selectedSymbolSession === 0 ? "Market for this symbol is closed" : ""}
          onClick={(e) => {
            placeOrder();
            mkRipple(e);
          }}
        >
          <b>{!isPositionOpening ? direction : "Loading..."}</b> <br />
          {quantity.toFixed(2) || ""} {selectedAuthSymbol} @{" "}
          {buyActive ? formatPrice(askPrice) : formatPrice(bidPrice)}
        </button>

        {selectedSymbolSession === 0 ? (
        <button className="mkt-closed-notification-btn" onClick={() => {changeOrderTab()}}>
          {'Market for this symbol is closed'}
        </button>
        ) : ('')}
      </div>

    </>
  );
};

export default MarketOrder;
